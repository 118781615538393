import { NgxUploaderModule } from "@angular-ex/uploader";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatSortModule } from "@angular/material/sort";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxStripeModule } from "ngx-stripe";

import { environment as env } from "../../environments/environment";
import { SharedProviderModule } from "../features/shared/shared-providers.module";
import { ReferACampaignEditModule } from "../refer/refer-a-brand.module";
import { AddressBoxComponent } from "../shared/components/address-box/address-box.component";
import { AddressSliderComponent } from "../shared/components/address-slider/address-slider.component";
import { CampaignPostStatsComponent } from "../shared/components/campaign-post-stats/campaign-post-stats.component";
import { ChartComponent } from "../shared/components/chart/chart.component";
import { EmailsInputComponent } from "../shared/components/emails-input/emails-input.component";
import { ImageCropperComponent } from "../shared/components/image-cropper/image-cropper.component";
import { LoaderComponent } from "../shared/components/loader/loader.component";
import { PhoneInputComponent } from "../shared/components/phone-input/phone-input.component";
import { ReloadComponent } from "../shared/components/reload/reload.component";
import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { TooltipComponent } from "../shared/components/tooltip/tooltip.component";
import { CanDeactivateGuard } from "../shared/guards/can-deactivate.guard";
import { SharedGuardsModule } from "../shared/guards/shared-guards.module";
import { ConnectToLinkedInInteractor } from "../shared/interactors/linkedin/connect-to-linkedin.interactor";
import { AngularMaterialModule } from "../shared/material/material.module";
import { FormatCountryCodePipe } from "../shared/pipes/format-country-code.pipe";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { LinkedInService } from "../shared/services/api/linkedin.service";
import { FacebookApiService } from "../shared/services/facebook-api.service";
import { FacebookService } from "../shared/services/facebook.service";
import { AddressEditionFormComponent } from "./address-edition-form/address-edition-form.component";
import { ChooseAddressDialogComponent } from "./choose-address-dialog/choose-address-dialog.component";
import { ConnectToLinkedInComponent } from "./connect-to-linkedin/connect-to-linkedin.component";
import { LoggedInAsPartnerGuard } from "./logged-in-as-partner.guard";
import { PartnerAccountAccessRequestDialogComponent } from "./partner-account-access-request/partner-account-access-request-dialog.component";
import { ContentPublishedDialogComponent } from "./partner-campaign-content-published-dialog/content-published-dialog.component";
import { PartnerCampaignInvalidShortUrlDialogComponent } from "./partner-campaign-invalid-short-url-dialog/partner-campaign-invalid-short-url-dialog.component";
import { CampaignPartnerPostComponent } from "./partner-campaign-post/partner-campaign-post.component";
import { PartnerCampaignPromoteAdDialogComponent } from "./partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { PartnerCampaignPromoteAdPaymentComponent } from "./partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-payment/partner-campaign-promote-ad-payment.component";
import { PartnerCampaignPromoteAdSettingsComponent } from "./partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-settings/partner-campaign-promote-ad-settings.component";
import { PartnerCampaignViewAdDialogComponent } from "./partner-campaign-view-ad-dialog/partner-campaign-view-ad-dialog.component";
import { DeletePostDialogComponent } from "./partner-campaign/delete-post-dialog/delete-post-dialog.component";
import { CampaignPostsCheckDialogComponent } from "./partner-campaign/partner-campaign-content-calendar/campaign-posts-check-dialog/campaign-posts-check-dialog.component";
import { PartnerCampaignItemComponent } from "./partner-campaign/partner-campaign-list/partner-campaign-item/partner-campaign-item.component";
import { PartnerCampaignListComponent } from "./partner-campaign/partner-campaign-list/partner-campaign-list.component";
import { PartnerCampaignModule } from "./partner-campaign/partner-campaign.module";
import { PartnerDashboardComponent } from "./partner-dashboard/partner-dashboard.component";
import { PartnerDeclineCampaignDialogComponent } from "./partner-decline-campaign-dialog/partner-decline-campaign-dialog.component";
import { PartnerDialogService } from "./partner-dialog.service";
import { PartnerPaymentsListComponent } from "./partner-payments-list/partner-payments-list.component";
import { CreatePartnerAccountExistsDialogComponent } from "./partner-profile/create-partner/create-partner-account-exists-dialog/create-partner-account-exists-dialog.component";
import { CreatePartnerComponent } from "./partner-profile/create-partner/create-partner.component";
import { EditPartnerComponent } from "./partner-profile/edit-partner/edit-partner.component";
import { LeaveDialogComponent } from "./partner-profile/edit-partner/leave-dialog/leave-dialog.component";
import { LogoEditionDialogComponent } from "./partner-profile/partner-profile-company-files-form/logo-edition-dialog/logo-edition-dialog.component";
import { PartnerProfileCompanyFilesFormComponent } from "./partner-profile/partner-profile-company-files-form/partner-profile-company-files-form.component";
import { PartnerProfileCompanyInformationFormCreateComponent } from "./partner-profile/partner-profile-company-information-form-create/partner-profile-company-information-form-create.component";
import { PartnerProfileCompanyInformationFormComponent } from "./partner-profile/partner-profile-company-information-form/partner-profile-company-information-form.component";
import { PartnerProfileContactInformationFormComponent } from "./partner-profile/partner-profile-contact-information-form/partner-profile-contact-information-form.component";
import { PartnerProfileProgressComponent } from "./partner-profile/partner-profile-progress/partner-profile-progress.component";
import { FacebookConnectionBoxComponent } from "./partner-profile/partner-profile-social-links-form/facebook-connection-box/facebook-connection-box.component";
import { GoogleConnectionBoxComponent } from "./partner-profile/partner-profile-social-links-form/google-connection-box/google-connection-box.component";
import { LinkedinConnectionBoxComponent } from "./partner-profile/partner-profile-social-links-form/linkedin-connection-box/linkedin-connection-box.component";
import { PartnerProfileSocialLinksSectionComponent } from "./partner-profile/partner-profile-social-links-form/parner-profile-social-links-section/partner-profile-social-links-section.component";
import { PartnerProfileSocialLinksFormComponent } from "./partner-profile/partner-profile-social-links-form/partner-profile-social-links-form.component";
import { PartnerShareFacebookConnectionDialogComponent } from "./partner-profile/partner-profile-social-links-form/partner-share-facebook-connection-dialog/partner-share-facebook-connection-dialog.component";
import { RedirectToPartnerIdBasedRouteGuard } from "./redirect-to-partner-id-based-route.guard";
import { PartnerResolverGuard } from "./shared/partner-resolver.guard";
import { PartnerCampaignAdService } from "./shared/services/partner-campaign-ad.service";
import { PartnerCampaignPostLogService } from "./shared/services/partner-campaign-post-log.service";
import { FacebookConnectDialogComponent } from "./third-party-connection-dialogs/facebook-connect-dialog/facebook-connect-dialog.component";
import { FacebookConnectInfoDialogComponent } from "./third-party-connection-dialogs/facebook-connect-info-dialog/facebook-connect-info-dialog.component";
import { FacebookNotConnectedDialogComponent } from "./third-party-connection-dialogs/facebook-not-connected-dialog/facebook-not-connected-dialog.component";
import { LinkedInNotConnectedDialogComponent } from "./third-party-connection-dialogs/linkedin-not-connected-dialog/linkedin-not-connected-dialog.component";
import { LinkedinOrganizationConnectDialogComponent } from "./third-party-connection-dialogs/linkedin-organization-connect-dialog/linkedin-organization-connect-dialog.component";
import { PartnerAuthorizeFacebookAdsDialogComponent } from "./third-party-connection-dialogs/partner-authorize-facebook-ads-dialog/partner-authorize-facebook-ads-dialog.component";
import { PartnerGoogleAdsConnectionDialogComponent } from "./third-party-connection-dialogs/partner-google-ads-connection-dialog/partner-google-ads-connection-dialog.component";
import { FacebookDisconnectDialogComponent } from "./third-party-connection-dialogs/third-party-disconnect-dialogs/facebook-disconnect-dialog/facebook-disconnect-dialog.component";
import { GoogleDisconnectDialogComponent } from "./third-party-connection-dialogs/third-party-disconnect-dialogs/google-disconnect-dialog/google-disconnect-dialog.component";
import { LinkedinDisconnectDialogComponent } from "./third-party-connection-dialogs/third-party-disconnect-dialogs/linkedin-disconnect-dialog/linkedin-disconnect-dialog.component";
import { UserManagementFormComponent } from "./user-management-form/user-management-form.component";

export const routes: Routes = [
  { path: "new", component: CreatePartnerComponent },
  { path: "linkedin-connection", component: ConnectToLinkedInComponent },
  {
    path: "",
    canActivate: [
      LoggedInAsPartnerGuard(),
      RedirectToPartnerIdBasedRouteGuard(),
    ],
    children: [
      { path: "edit", component: EditPartnerComponent },
      {
        path: "refer-a-brand",
        loadChildren: () =>
          import("../refer/refer-a-brand.module").then(
            (m) => m.ReferACampaignEditModule,
          ),
        data: { preload: true },
      },
      { path: "payments", component: PartnerPaymentsListComponent },
      { path: "campaigns", component: PartnerCampaignListComponent },
      // redirect all campaign/id/view to campaigns/id
      {
        path: "campaigns/:campaignId",
        loadChildren: () =>
          import("./partner-campaign/partner-campaign.module").then(
            (m) => m.PartnerCampaignModule,
          ),
      },
      { path: "dashboard", component: PartnerDashboardComponent },
    ],
  },
  {
    path: ":partnerId",
    canActivate: [LoggedInAsPartnerGuard(), PartnerResolverGuard()],
    children: [
      {
        path: "edit",
        component: EditPartnerComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "refer-a-brand",
        loadChildren: () =>
          import("../refer/refer-a-brand.module").then(
            (m) => m.ReferACampaignEditModule,
          ),
        data: { preload: true },
      },
      { path: "payments", component: PartnerPaymentsListComponent },
      { path: "campaigns", component: PartnerCampaignListComponent },
      {
        path: "campaigns/:campaignId",
        loadChildren: () =>
          import("./partner-campaign/partner-campaign.module").then(
            (m) => m.PartnerCampaignModule,
          ),
      },
      { path: "dashboard", component: PartnerDashboardComponent },
    ],
  },
];

const DIALOG_COMPONENTS = [
  CampaignPostsCheckDialogComponent,
  ChooseAddressDialogComponent,
  ContentPublishedDialogComponent,
  CreatePartnerAccountExistsDialogComponent,
  DeletePostDialogComponent,
  FacebookConnectDialogComponent,
  FacebookConnectInfoDialogComponent,
  FacebookDisconnectDialogComponent,
  FacebookNotConnectedDialogComponent,
  GoogleDisconnectDialogComponent,
  LeaveDialogComponent,
  LinkedinDisconnectDialogComponent,
  LinkedInNotConnectedDialogComponent,
  LinkedinOrganizationConnectDialogComponent,
  LogoEditionDialogComponent,
  PartnerAccountAccessRequestDialogComponent,
  PartnerAuthorizeFacebookAdsDialogComponent,
  PartnerCampaignInvalidShortUrlDialogComponent,
  PartnerCampaignPromoteAdDialogComponent,
  PartnerCampaignViewAdDialogComponent,
  PartnerDeclineCampaignDialogComponent,
  PartnerGoogleAdsConnectionDialogComponent,
  PartnerShareFacebookConnectionDialogComponent,
];

@NgModule({
  declarations: [
    CreatePartnerComponent,
    ConnectToLinkedInComponent,
    EditPartnerComponent,
    PartnerProfileCompanyInformationFormComponent,
    PartnerProfileCompanyInformationFormCreateComponent,
    PartnerProfileContactInformationFormComponent,
    PartnerProfileSocialLinksFormComponent,
    PartnerProfileCompanyFilesFormComponent,
    UserManagementFormComponent,
    PartnerPaymentsListComponent,
    PartnerCampaignListComponent,
    PartnerCampaignItemComponent,
    PartnerDashboardComponent,
    PartnerProfileProgressComponent,
    CampaignPartnerPostComponent,
    AddressEditionFormComponent,
    PartnerCampaignPromoteAdSettingsComponent,
    PartnerCampaignPromoteAdPaymentComponent,
    GoogleConnectionBoxComponent,
    FacebookConnectionBoxComponent,
    LinkedinConnectionBoxComponent,
    PartnerProfileSocialLinksSectionComponent,
    ...DIALOG_COMPONENTS,
  ],
  providers: [
    CanDeactivateGuard,
    ConnectToLinkedInInteractor,
    FacebookApiService,
    FacebookService,
    FormatCountryCodePipe,
    LinkedInService,
    PartnerCampaignAdService,
    PartnerCampaignPostLogService,
    PartnerDialogService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedComponentsModule,
    SharedPipesModule,
    AngularMaterialModule,
    SharedGuardsModule,
    MatSortModule,
    RouterModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxStripeModule.forRoot(env.stripeApiKey),
    ReferACampaignEditModule,
    NgxUploaderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedProviderModule,
    AngularMaterialModule,
    RouterModule.forChild(routes),
    LoaderComponent,
    EmailsInputComponent,
    PhoneInputComponent,
    PartnerCampaignModule,
    ChartComponent,
    ImageCropperComponent,
    CampaignPostStatsComponent,
    AddressBoxComponent,
    AddressSliderComponent,
    TooltipComponent,
    ReloadComponent,
  ],
})
export class PartnerModule {}
