import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StorageKeys } from "../../shared/services/local-storage.service";
import { safeLocalStorage } from "../../shared/utils/safe-storage";

@Component({
  template: ``,
})
export class ConnectToLinkedInComponent {
  private request: unknown;

  constructor(private readonly route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.request = params;
      safeLocalStorage.setItem(
        StorageKeys.LinkedInConnection,
        JSON.stringify(this.request),
      );
      window.self.close();
    });
  }
}
