import { Component, EventEmitter, Input, Output, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, lastValueFrom } from "rxjs";
import { AlertDialogComponent } from "../../../../shared/components/alert-dialog/alert-dialog.component";
import { Partner } from "../../../../shared/models/partner";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { FacebookService } from "../../../../shared/services/facebook.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { FacebookConnectDialogComponent } from "../../../third-party-connection-dialogs/facebook-connect-dialog/facebook-connect-dialog.component";
import { FacebookDisconnectDialogComponent } from "../../../third-party-connection-dialogs/third-party-disconnect-dialogs/facebook-disconnect-dialog/facebook-disconnect-dialog.component";
import { FacebookConnectInfoDialogComponent } from "../../../third-party-connection-dialogs/facebook-connect-info-dialog/facebook-connect-info-dialog.component";
import { PartnerShareFacebookConnectionDialogData } from "../partner-share-facebook-connection-dialog/partner-share-facebook-connection-dialog-data";
import { PartnerShareFacebookConnectionDialogComponent } from "../partner-share-facebook-connection-dialog/partner-share-facebook-connection-dialog.component";
import {
  FacebookNotInitializedError,
  FacebookPageInfoResponse,
} from "../../../../shared/entities/facebook";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  selector: "app-facebook-connection-box",
  templateUrl: "./facebook-connection-box.component.html",
  styleUrl: "./facebook-connection-box.component.scss",
})
export class FacebookConnectionBoxComponent {
  @Input({ required: true }) public partner!: Partner;
  @Output() public facebookChanged = new EventEmitter<void>();

  protected connectingToFB = false;
  private readonly subs = new Subscription();
  protected readonly isClaimLoading = signal(false);

  constructor(
    private readonly facebookService: FacebookService,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly dialog: MatDialog,
  ) {}

  private async fbLogin(forceLogout: boolean): Promise<void> {
    try {
      const accessToken = await this.facebookService.login(forceLogout);
      if (accessToken) {
        this.openFacebookConnectDialog();
      } else {
        this.notificationService.error(
          "partner.edit.facebookConnectPermissionsError",
        );
      }
    } catch (error) {
      const message =
        error instanceof FacebookNotInitializedError
          ? error.message
          : "partner.edit.facebookConnectPermissionsError";
      this.notificationService.error(message);
    }
  }

  private async openFacebookConnectDialog(): Promise<void> {
    this.connectingToFB = true;
    const connectionData = await lastValueFrom(
      this.dialog
        .open(FacebookConnectDialogComponent, {
          width: "550px",
          disableClose: true,
        })
        .afterClosed(),
    );

    if (connectionData) {
      this.connectPartnerToFacebook(connectionData);
    } else {
      this.connectingToFB = false;
    }
  }

  private connectPartnerToFacebook(newPage: FacebookPageInfoResponse) {
    this.partnerService
      .connectPartnerToFacebook(
        newPage.id,
        newPage.accessToken,
        newPage.name,
        newPage.likes,
        newPage.picture,
        [],
      )
      .subscribe({
        next: (partner) => {
          this.partnerService.setCurrentPartner(partner);
          this.partnerService.profileProgressUpdated.emit();
          this.notificationService.success("shared.changesSavedSuccessfully");
          this.onEventSubmit();
          this.connectingToFB = false;
        },
        error: () => {
          this.connectingToFB = false;
          this.dialog.open(AlertDialogComponent, {
            width: "550px",
            data: {
              message: "shared.errorConnectingToFacebook",
              subDescription: "shared.errorConnectingToFacebookMessage",
            },
          });
        },
      });
  }

  protected showShareFacebookConnectionDialog(): void {
    this.dialog.open(PartnerShareFacebookConnectionDialogComponent, {
      width: "600px",
      data: new PartnerShareFacebookConnectionDialogData(this.partner),
      panelClass: "overflow-visible-dialog",
    });
  }

  protected disconnectDialog(): void {
    this.dialog
      .open(FacebookDisconnectDialogComponent, {
        width: "512px",
        data: this.partner,
        panelClass: "overflow-visible-dialog",
      })
      .afterClosed()
      .subscribe((disconnect) => {
        if (disconnect) {
          this.onEventSubmit();
        }
      });
  }

  protected showFacebookConnectInfoDialog(forceLogout = false): void {
    this.dialog
      .open(FacebookConnectInfoDialogComponent, {
        width: "600px",
        panelClass: "overflow-visible-dialog",
      })
      .afterClosed()
      .subscribe((connect) => {
        if (connect) {
          this.fbLogin(forceLogout);
        }
      });
  }

  protected onEventSubmit(): void {
    this.facebookChanged.emit();
  }

  protected checkClaimStatus(): void {
    this.isClaimLoading.set(true);
    this.subs.add(
      this.partnerService.claimStatus(this.partner.id).subscribe({
        next: (partner: Partner) => {
          this.partner.facebookPageClaimStatus =
            partner.facebookPageClaimStatus;
          if (this.partner.isAuthorizedFBAds) {
            this.partner.facebookPageClaimStatus =
              partner.facebookPageClaimStatus;
          }
          this.isClaimLoading.set(false);
        },
        error: () => {
          this.isClaimLoading.set(false);
        },
      }),
    );
  }
}
