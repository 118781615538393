<h2 *ngIf="partnerAccountExistsResponses.length === 1" mat-dialog-title>
  {{ "partner.create.accountExistsDialog.title" | translate }}
</h2>
<h2 *ngIf="partnerAccountExistsResponses.length > 1" mat-dialog-title>
  {{ "partner.create.accountExistsDialog.titleMany" | translate }}
</h2>
<mat-dialog-content>
  <div *ngIf="partnerAccountExistsResponses.length === 1">
    <div class="description">
      {{ "partner.create.accountExistsDialog.oneAccountDetected" | translate }}
    </div>
    <div class="confirm-message">
      {{ "partner.create.accountExistsDialog.isThisYourAccount" | translate }}
    </div>
    <ul>
      <li class="list-header">
        {{ "partner.create.accountExistsDialog.account" | translate }}
      </li>
      <ng-container>
        <li class="account-row">
          <span class="account-details">{{
            getSingleAccount().companyName
          }}</span>
          <span class="account-details">
            {{ getSingleAccount().billingAddressStreetAndNumber }}
          </span>
          <span class="account-details">
            {{
              getSingleAccount().billingAddressPostalCode +
                " " +
                getSingleAccount().billingAddressCity
            }}
          </span>
          <span class="account-details">
            {{ getSingleAccount().billingAddressCountry }}
          </span>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="partnerAccountExistsResponses.length > 1">
    <div class="description">
      {{
        "partner.create.accountExistsDialog.multipleAccountsDetected"
          | translate
      }}
    </div>
    <div class="confirm-message">
      {{ "partner.create.accountExistsDialog.pleaseSelectAccount" | translate }}
    </div>
    <div class="description">
      {{ "partner.create.accountExistsDialog.orContinueCreating" | translate }}
    </div>
    <mat-radio-group
      name="selectedAccountId"
      class="accounts-radio-group"
      [(ngModel)]="selectedAccountId"
    >
      <ul>
        <li class="list-header">
          {{ "partner.create.accountExistsDialog.accounts" | translate }}
        </li>
        <ng-container
          *ngFor="
            let partnerAccountExistsResponse of partnerAccountExistsResponses
          "
        >
          <li>
            <div class="partner-row">
              <mat-radio-button [value]="partnerAccountExistsResponse.id">
                <span class="confirm-details">{{
                  partnerAccountExistsResponse.companyName
                }}</span>
                <span class="confirm-details">
                  {{
                    partnerAccountExistsResponse.billingAddressStreetAndNumber
                  }}
                </span>
                <span class="confirm-details">
                  {{
                    partnerAccountExistsResponse.billingAddressPostalCode +
                      " " +
                      partnerAccountExistsResponse.billingAddressCity
                  }}
                </span>
                <span class="confirm-details">
                  {{ partnerAccountExistsResponse.billingAddressCountry }}
                </span>
              </mat-radio-button>
            </div>
          </li>
        </ng-container>
      </ul>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="button-actions">
  <button class="round-button round-button--grey" (click)="answerNo()">
    {{ "shared.no" | translate }}
  </button>
  <button class="round-button round-button--orange" (click)="answerYes()">
    {{ "shared.yes" | translate }}
  </button>
</mat-dialog-actions>
