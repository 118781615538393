import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { PartnerProfileSocialLinksSectionComponent } from "./parner-profile-social-links-section/partner-profile-social-links-section.component";

@Component({
  selector: "app-partner-profile-social-links-form",
  templateUrl: "./partner-profile-social-links-form.component.html",
  styleUrl: "./partner-profile-social-links-form.component.scss",
})
export class PartnerProfileSocialLinksFormComponent {
  @Input({ required: true }) public partner!: Partner;
  @Input() public isLoading = false;
  @Input() public isEditForm = false;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public formSubmit = new EventEmitter<boolean>();

  @ViewChild("linksSection")
  public linksSection!: PartnerProfileSocialLinksSectionComponent;

  constructor(private readonly partnerService: PartnerService) {}

  protected onActionCancel(): void {
    this.cancel.emit();
  }

  protected saveConnectionChanges(): void {
    this.onActionSubmit(false);
  }

  protected onActionSubmit(goToNextStep = true): void {
    Object.keys(this.linksSection.form.controls).forEach((key: string) => {
      const abstractControl = this.linksSection.form.controls[key];
      abstractControl.markAsTouched();
    });

    if (!this.linksSection.form.valid) {
      return;
    }

    this.partnerService.profileProgressUpdated.emit();
    this.formSubmit.emit(goToNextStep);
  }
}
