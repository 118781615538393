import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Address } from "../../../shared/models/address";
import {
  AppData,
  AppDataCurrency,
  AppDataLanguage,
  AppDataSector,
} from "../../../shared/models/appData";
import { Partner } from "../../../shared/models/partner";
import { COMPANY_ADDITIONAL_INFO_MAX_LENGTH } from "../partner-profile-company-information-form/partner-profile-company-information-form.component";

@Component({
  selector: "app-partner-profile-company-information-form-create",
  templateUrl:
    "./partner-profile-company-information-form-create.component.html",
  styleUrl: "./partner-profile-company-information-form-create.component.scss",
})
export class PartnerProfileCompanyInformationFormCreateComponent
  implements OnInit
{
  @Input({ required: true }) public appData!: AppData;
  @Input({ required: true }) public isLoading!: boolean;
  @Input({ required: true }) public partner!: Partner;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public formSubmit = new EventEmitter<void>();

  protected readonly COMPANY_ADDITIONAL_INFO_MAX_LENGTH =
    COMPANY_ADDITIONAL_INFO_MAX_LENGTH;
  protected billingAddress!: Address;
  protected currencies!: AppDataCurrency[];
  protected isShippingAddressSame = true;
  protected languages!: AppDataLanguage[];
  protected sectors!: AppDataSector[];
  protected shippingAddress!: Address;

  public ngOnInit(): void {
    this.sectors = this.appData.sectors.sort((s1, s2) =>
      s1.name.localeCompare(s2.name),
    );
    this.languages = this.appData.languages.sort((l1, l2) =>
      l1.name.localeCompare(l2.name),
    );
    this.currencies = this.appData.currencies;
    this.billingAddress = this.partner.billingAddress;
    this.shippingAddress = this.partner.shippingAddress;
  }

  protected onSubmit(form: UntypedFormGroup, event: Event): void {
    event.stopPropagation();

    if (!form.valid) {
      return;
    }

    if (this.isShippingAddressSame) {
      this.copyAddressToShippingAddress();
    }

    if (this.isShippingAddressSame || this.shippingAddress.isComplete) {
      this.formSubmit.emit();
    }
  }

  public toggleShippingAddress(): void {
    if (this.isShippingAddressSame) {
      this.copyAddressToShippingAddress();
    } else {
      this.clearShippingAddress();
    }
  }

  private copyAddressToShippingAddress(): void {
    this.billingAddress.isDefault = true;
    this.shippingAddress.streetAndNumber = this.billingAddress.streetAndNumber;
    this.shippingAddress.postalCode = this.billingAddress.postalCode;
    this.shippingAddress.city = this.billingAddress.city;
    this.shippingAddress.country = this.billingAddress.country;
    this.shippingAddress.additionalInformation =
      this.billingAddress.additionalInformation ?? "";
  }

  private clearShippingAddress(): void {
    this.shippingAddress.streetAndNumber = "";
    this.shippingAddress.postalCode = "";
    this.shippingAddress.city = "";
    this.shippingAddress.country = "";
    this.shippingAddress.additionalInformation = "";
  }

  protected companyNameChanged(): void {
    this.partner.slug = this.partner.companyName
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  protected isSubmitEnabled(form: UntypedFormGroup): boolean {
    return (
      !this.isLoading &&
      form.valid &&
      this.billingAddress.isComplete &&
      (this.isShippingAddressSame || this.shippingAddress.isComplete)
    );
  }
}
