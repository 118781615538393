<h2 mat-dialog-title>
  {{ "partner.shareFacebookConnectionDialog.title" | translate }}
</h2>
<mat-dialog-content>
  <div>
    {{ "partner.shareFacebookConnectionDialog.description" | translate }}
  </div>
  <div *ngIf="loading" class="loading-progress">
    <mat-progress-bar
      [color]="'accent'"
      class="loading-progress-bar"
      mode="indeterminate"
    >
    </mat-progress-bar>
  </div>
  <ul *ngIf="!loading">
    <li class="list-header">
      {{
        "partner.shareFacebookConnectionDialog.availablePartners" | translate
      }}
    </li>
    <ng-container *ngFor="let partner of partnersArray">
      <li>
        <div class="partner-row">
          <mat-checkbox
            name="display{{ partner.id }}"
            [checked]="partner.selected"
            (change)="partner.selected = !partner.selected"
          >
            <span class="partner-row-label">
              {{ partner.companyName }}
            </span>
          </mat-checkbox>
        </div>
      </li>
    </ng-container>
  </ul>
</mat-dialog-content>
<mat-dialog-actions class="button-actions">
  <button
    [disabled]="loading"
    class="round-button round-button--grey"
    [mat-dialog-close]="false"
  >
    {{ "shared.cancel" | translate }}
  </button>
  <button
    [disabled]="loading"
    class="round-button round-button--orange"
    (click)="shareFacebookConnection()"
  >
    {{ "shared.save" | translate }}
  </button>
</mat-dialog-actions>
