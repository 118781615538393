import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";

import { Payment } from "../../shared/models/payment";
import { PartnerPaymentService } from "../shared/services/partner-payment.service";
import { NotificationService } from "../../shared/services/notification.service";
import { getDateTimeFormatSignal } from "../../shared/services/date.service";

@Component({
  selector: "app-partner-payments-list",
  templateUrl: "./partner-payments-list.component.html",
  styleUrl: "./partner-payments-list.component.scss",
  providers: [PartnerPaymentService],
})
export class PartnerPaymentsListComponent implements OnInit, OnDestroy {
  protected readonly getDateTimeFormat = getDateTimeFormatSignal();
  protected readonly displayedColumns = [
    "invoiceId",
    "campaignName",
    "createdAt",
    "amount",
    "completed",
    "actions",
  ];

  protected loading = true;
  protected paymentsDataSource!: MatTableDataSource<Payment>;
  protected totalPayments = 0;

  private limit = 150;
  private offset = 0;
  private subscriptions = new Subscription();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly partnerPaymentService: PartnerPaymentService,
  ) {}

  public ngOnInit(): void {
    this.loadPayments();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private loadPayments(): void {
    this.loading = true;

    this.subscriptions.add(
      this.partnerPaymentService
        .getPayments(this.offset, this.limit)
        .subscribe({
          next: (list) => {
            this.paymentsDataSource = new MatTableDataSource(list.items);
            this.totalPayments = list.totalCount;
            this.loading = false;
          },
          error: () => {
            this.paymentsDataSource = new MatTableDataSource<Payment>([]);
            this.totalPayments = 0;
            this.loading = false;
            this.notificationService.error("shared.errorLoadingTheList");
          },
        }),
    );
  }

  protected downloadInvoice(element: any): void {
    element.downloading = true;

    this.subscriptions.add(
      this.partnerPaymentService
        .getPaymentInvoiceLink(element.id)
        .subscribe((invoiceLink) => {
          element.downloading = false;

          if (window) {
            window.open(invoiceLink, "_blank");
          }
        }),
    );
  }
}
