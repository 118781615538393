import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Partner } from "../../../shared/models/partner";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { RouteWithDataService } from "../../../shared/services/route-with-data.service";
import { safeLocalStorage } from "../../../shared/utils/safe-storage";
import { PartnerUrl } from "../../partner.url";

const BANNER_COOKIE = "profileCompletionBannerDismissed";

@Component({
  selector: "app-partner-profile-progress",
  templateUrl: "./partner-profile-progress.component.html",
  styleUrl: "./partner-profile-progress.component.scss",
})
export class PartnerProfileProgressComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public partner!: Partner;

  protected readonly PartnerUrl = PartnerUrl;
  protected profileProgressPercentage = 0;

  private subscription = new Subscription();

  constructor(
    private readonly partnerService: PartnerService,
    private readonly routeWithDataService: RouteWithDataService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.checkProgress();

    this.subscription.add(
      this.partnerService.profileProgressUpdated.subscribe(() => {
        this.checkProgress();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected routeWithData(route: any, tab = 0): void {
    // Force router to refresh route if we're currently viewing the same page
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigated = false;
    this.routeWithDataService.tabIndex = tab;
    this.router.navigate(route);
  }

  private checkProgress(): void {
    this.profileProgressPercentage = 0;
    if (this.partner.areGoogleAdsAuthorized) {
      this.profileProgressPercentage += 25;
    }
    if (this.partner.isConnectedToFB) {
      this.profileProgressPercentage += 25;
    }
    if (this.partner.isAuthorizedFBAds) {
      this.profileProgressPercentage += 25;
    }
    if (this.partner.hasUploadedFiles) {
      this.profileProgressPercentage += 25;
    }
  }

  protected isProgressBarVisible(): boolean {
    return (
      safeLocalStorage.getItem(BANNER_COOKIE + "-" + this.partner.id) !== "1"
    );
  }

  protected dismissProgressBar(): void {
    safeLocalStorage.setItem(BANNER_COOKIE + "-" + this.partner.id, "1");
  }
}
