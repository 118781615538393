<div class="loader-wrapper">
  <section class="container">
    <section
      class="campaigns-section"
      *ngIf="totalInitialCampaigns !== 0 && !loadingError"
    >
      <div class="campaign-list">
        <div class="campaign-item-join">
          <img src="/assets/images/as_add_campaign.svg" />
          <div class="text">
            <h3>{{ "partner.campaign.list.addCampaign" | translate }}</h3>
            <p [innerHTML]="'partner.campaign.list.code' | translate"></p>
          </div>
          <form
            class="no-campaigns__form"
            #f="ngForm"
            (ngSubmit)="addPartnerToCampaign(f)"
          >
            <mat-form-field class="no-campaigns__invite-code">
              <mat-label>{{
                "partner.campaign.list.inviteCode" | translate
              }}</mat-label>
              <input name="code" ngModel matInput />
            </mat-form-field>
            <button class="button-orange no-campaigns__join" type="submit">
              {{ "partner.campaign.list.joinCampaign" | translate }}
            </button>
          </form>
        </div>
      </div>
    </section>
    <section
      class="campaigns-section"
      *ngIf="totalInitialCampaigns === 0 && !loading && !loadingError"
    >
      <div class="campaigns-section__title">
        <h2>{{ "partner.campaign.list.noCampaigns" | translate }}</h2>
      </div>
      <div class="campaigns-section__text">
        <p>{{ "partner.campaign.list.welcomeTitle" | translate }}</p>
        <p [innerHTML]="'campaign.list.noCampaigns.code' | translate"></p>
        <form
          class="no-campaigns__form"
          #f="ngForm"
          (ngSubmit)="addPartnerToCampaign(f)"
        >
          <mat-form-field class="no-campaigns__invite-code">
            <mat-label>{{
              "partner.campaign.list.inviteCode" | translate
            }}</mat-label>
            <input name="code" ngModel matInput />
          </mat-form-field>
          <button class="button-orange no-campaigns__join" type="submit">
            {{ "partner.campaign.list.joinCampaign" | translate }}
          </button>
        </form>
      </div>
    </section>
    <section *ngIf="totalInitialCampaigns !== 0" class="campaigns-section">
      <div class="campaign-filters">
        <div class="status-filter" (clickOutside)="showFilter = false">
          <button class="button" (click)="showFilter = !showFilter">
            {{
              (filterSelected()
                ? "partner.campaign.list.campaigns"
                : "partner.campaign.list.allCampaigns"
              ) | translate
            }}
            ({{ totalCampaigns }})
            <div class="arrow"></div>
          </button>
          <div class="filter-menu" *ngIf="showFilter">
            <ul class="filter-list">
              <li class="filter-item" (click)="onViewAllCampaigns()">
                <img src="/assets/images/icons/ic_viewAll.svg" />
                <span>{{
                  "partner.campaignList.filtersViewAll" | translate
                }}</span>
              </li>
              @for (
                filter of CAMPAIGN_STATUS_OPTIONS | keyvalue: valueAscOrder;
                track filter.key
              ) {
                <li
                  class="filter-item"
                  [ngClass]="{
                    'filter-item--active': isFilterActive(filter.key)
                  }"
                  (click)="onFilterCampaigns(filter.key)"
                >
                  <img src="/assets/images/icons/{{ filter.value.icon }}.svg" />
                  <span>{{ filter.value.label | translate }}</span>
                </li>
              }
            </ul>
          </div>
        </div>
        <button class="sort-button" (click)="onChangeOrder()">
          <span>{{ "partner.campaign.list.byDate" | translate }}</span>
          <mat-icon [ngClass]="{ 'mirror-y': sortMode === SORT_MODES.Asc }"
            >sort</mat-icon
          >
        </button>
      </div>
    </section>
    <section *ngIf="totalInitialCampaigns !== 0" class="campaigns-section">
      <div
        class="campaign-list"
        [ngClass]="{ 'campaign-list--campaigns': totalInitialCampaigns !== 0 }"
      >
        @if (shownCampaigns && shownCampaigns?.length > 0 && !loading) {
          @for (campaign of shownCampaigns; track campaign.id) {
            <app-partner-campaign-item
              [campaign]="campaign"
              (handleRequiredAction)="handleRequiredAction(campaign, $event)"
            />
          }
        }
      </div>
      <app-loader
        [text]="'shared.loadingCampaign' | translate"
        [ngClass]="{ visible: loading }"
        noCover
      />
      <div class="paginator">
        <mat-paginator
          [hidePageSize]="true"
          [length]="totalCampaigns"
          [pageSize]="LIMIT"
          (page)="onPageChange($event)"
        />
      </div>
    </section>
  </section>
  <app-loader
    [text]="'shared.loadingCampaign' | translate"
    [ngClass]="{ visible: loadingFirstTime }"
  />
</div>
<div *ngIf="loadingError" class="reload-wrap">
  <app-reload (action)="getCampaigns()" />
</div>
