<a class="campaign-item" href="{{ campaignUrl() }}">
  <div class="campaign-item-img">
    <img
      class="campaign-item-img-hero"
      src="{{ heroImageUrl() }}"
      loading="lazy"
    />
    <div class="partner-campaign-status-holder">
      <app-partner-campaign-status [campaign]="campaign()" [isHeader]="true" />
    </div>
  </div>
  <div class="campaign-item-body">
    <div>
      <div class="campaign-item-name">{{ campaign().name }}</div>
      <div class="campaign-type-container">
        <span class="campaign-type">{{
          "shared.campaignType." + campaign().type | translate
        }}</span>
        <div class="campaign-item-status">
          <img src="/assets/images/icons/{{ status().icon }}.svg" />
          <span>{{ status().label | translate }}</span>
        </div>
      </div>
      <div class="meta">
        <div class="campaign-item-period">
          <mat-icon>insert_invitation</mat-icon>
          <span
            class="campaign-item-dates"
            *ngIf="campaign().startDate && campaign().endDate"
            [innerHTML]="
              'partner.campaignList.fromDates'
                | translate
                  : {
                      startDate:
                        campaign().startDate | localizeDate: 'shortDate',
                      endDate: campaign().endDate | localizeDate: 'shortDate'
                    }
            "
          >
          </span>
          <span
            class="campaign-item-dates"
            *ngIf="!campaign().startDate || !campaign().endDate"
          >
            {{ "partner.campaignList.datesToBeConfirmed" | translate }}
          </span>
        </div>
        <div class="campaign-item-participants">
          @if (shouldShowParticipants()) {
            <mat-icon>person</mat-icon
            ><span class="campaign-item-participants"
              >{{ campaign().numberOfParticipants }}
              {{ "partner.campaignList.participants" | translate }}
            </span>
          } @else {
            &nbsp;
          }
        </div>
      </div>
    </div>
    <div class="campaign-item-footer">
      <div class="sn-links">
        @for (network of socialNetworks(); track network) {
          <img
            alt="{{ network.name }}"
            class="logo"
            src="/assets/images/icons{{ network.icon }}"
          />
        }
      </div>
      <div class="actions">
        @if (requiredAction()) {
          <button
            class="action"
            (click)="
              $event.preventDefault();
              handleRequiredAction.emit(requiredAction().action)
            "
          >
            <img src="/assets/images/icons{{ requiredAction().icon }}" />
            <span>{{
              "partner.campaignList.requiredAction." + requiredAction().label
                | translate
            }}</span>
          </button>
        }
      </div>
    </div>
  </div>
</a>
