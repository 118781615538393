import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import dayjs from "dayjs";

import { Payment } from "../../../shared/models/payment";
import { HttpClient } from "../../../shared/services/http-client";
import { PartnerPaymentsList } from "../../../shared/services/responses/partner-payments-list";
import { PartnerService } from "../../../shared/services/api/partner.service";

@Injectable()
export class PartnerPaymentService {
  constructor(
    private readonly http: HttpClient,
    private readonly partnerService: PartnerService,
  ) {}

  private getCurrentPartnerId(): number {
    return this.partnerService.currentPartnerId;
  }

  public getPayments(
    offset: number,
    limit: number,
  ): Observable<PartnerPaymentsList> {
    const partnerId = this.getCurrentPartnerId();

    if (!partnerId) {
      return of(new PartnerPaymentsList([], 0));
    }

    return this.http
      .get(`partner/${partnerId}/payments?offset=${offset}&limit=${limit}`)
      .pipe(
        map(
          ({ body }) =>
            new PartnerPaymentsList(
              body.items.map(
                (data: any) =>
                  new Payment(
                    data.id,
                    data.campaignId,
                    data.amountInCents,
                    dayjs(data.createdAt).toDate(),
                  ),
              ),
              body._meta?.totalCount ?? 0,
            ),
        ),
      );
  }

  public getPaymentInvoiceLink(paymentId: number): Observable<string> {
    const partnerId = this.getCurrentPartnerId();

    return this.http
      .get(`partner/${partnerId}/payment_invoice/${paymentId}`)
      .pipe(map(({ body }) => body.link));
  }
}
