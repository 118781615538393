import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  computed,
  inject,
  input,
} from "@angular/core";
import {
  PartnerCampaign,
  PartnerCampaignStatus,
  PartnerCampaignStatusAttrs,
} from "../../../../shared/models/partnerCampaign";
import { PartnerUrl } from "../../../partner.url";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import {
  CampaignRequiredAction,
  CampaignType,
} from "../../../../shared/enums/campaign.enums";
import { socialNetworksSignal } from "../../../../brand/brand-campaign/brand-campaign-list/brand-campaign-item/brand-campaign-item.component";

interface RequiredActionVM {
  action: CampaignRequiredAction;
  icon: string;
  label: string;
}

@Component({
  selector: "app-partner-campaign-item",
  templateUrl: "./partner-campaign-item.component.html",
  styleUrl: "./partner-campaign-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerCampaignItemComponent {
  public readonly campaign = input.required<PartnerCampaign>();

  @Output() public readonly handleRequiredAction =
    new EventEmitter<CampaignRequiredAction>();

  private readonly partnerService = inject(PartnerService);

  protected readonly campaignUrl = computed(() =>
    PartnerUrl.CampaignHome(
      this.partnerService.currentPartnerId,
      this.campaign().id,
    ),
  );

  protected readonly heroImageUrl = computed(
    () =>
      this.campaign().listImageUrl ?? "/assets/images/draft-generic-image.jpg",
  );

  protected readonly requiredAction = computed<RequiredActionVM>(() => {
    const actions: Record<CampaignRequiredAction, RequiredActionVM> = {
      [CampaignRequiredAction.AddConnection]: {
        action: CampaignRequiredAction.AddConnection,
        icon: "/ic_Connection.svg",
        label: "addConnection",
      },
      [CampaignRequiredAction.AcceptInvitation]: {
        action: CampaignRequiredAction.AcceptInvitation,
        icon: "/ic_accept.svg",
        label: "acceptInvitation",
      },
      [CampaignRequiredAction.BudgetLeft]: {
        action: CampaignRequiredAction.BudgetLeft,
        icon: "/ic_Budget_Left.svg",
        label: "addConnection",
      },
      [CampaignRequiredAction.ShowResults]: {
        action: CampaignRequiredAction.ShowResults,
        icon: "/ic_Show_Results.svg",
        label: "showResults",
      },
      [CampaignRequiredAction.StartCampaign]: {
        action: CampaignRequiredAction.StartCampaign,
        icon: "/ic_Start_Campaign.svg",
        label: "startCampaign",
      },
    };

    return actions[this.campaign().requiredPartnerAction];
  });

  protected readonly shouldShowParticipants = computed(
    () =>
      this.campaign().numberOfParticipants !== null &&
      this.campaign().numberOfParticipants !== undefined &&
      (this.campaign().type === CampaignType.Contest ||
        this.campaign().type === CampaignType.ProductTester),
  );

  protected readonly socialNetworks = socialNetworksSignal(this.campaign);

  protected readonly status = computed(() => {
    if (this.campaign().isOngoing) {
      return PartnerCampaignStatusAttrs[PartnerCampaignStatus.Active];
    } else if (this.campaign().hasFinished) {
      return PartnerCampaignStatusAttrs[PartnerCampaignStatus.Past];
    } else {
      return PartnerCampaignStatusAttrs[PartnerCampaignStatus.Upcoming];
    }
  });
}
