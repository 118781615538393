import { DataSettings } from "../../../shared/charts/models/data-settings.model";
import { KPIChartFilters } from "../partner-dashboard.component";

export const partnerDashboardAllKpisChartDataSettings: DataSettings<
  keyof KPIChartFilters,
  keyof KPIChartFilters
>[] = [
  new DataSettings(
    "publishedPosts",
    "publishedPosts",
    "partner.dashboard.KPIChart.publishedPosts",
    "#4a90e2",
  ),
  new DataSettings(
    "postViews",
    "postViews",
    "partner.dashboard.KPIChart.postViews",
    "#f3a7f0",
  ),
  new DataSettings(
    "likes",
    "likes",
    "partner.dashboard.KPIChart.likes",
    "#1dbf77",
  ),
  new DataSettings(
    "publishedAds",
    "publishedAds",
    "partner.dashboard.KPIChart.publishedAds",
    "#f3cda7",
  ),
  new DataSettings(
    "spentBudget",
    "spentBudget",
    "partner.dashboard.KPIChart.spentBudget",
    "#f28a3d",
  ),
  new DataSettings(
    "adViews",
    "adViews",
    "partner.dashboard.KPIChart.adViews",
    "#a7f3ef",
  ),
  new DataSettings(
    "reach",
    "reach",
    "partner.dashboard.KPIChart.reach",
    "#ca93f4",
  ),
  new DataSettings(
    "linkClicks",
    "linkClicks",
    "partner.dashboard.KPIChart.linkClicks",
    "#a4daf7",
  ),
];
