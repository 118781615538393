import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";

import { UrlPrefix, UrlPrefixes } from "../../../../shared/models/appData";
import { Partner } from "../../../../shared/models/partner";
import { CustomFormValidators } from "../../../../shared/validators/custom-form-validators";

@Component({
  selector: "app-partner-profile-social-links-section",
  templateUrl: "./partner-profile-social-links-section.component.html",
  styleUrl: "./partner-profile-social-links-section.component.scss",
})
export class PartnerProfileSocialLinksSectionComponent implements OnInit {
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public isEditForm!: boolean;

  @Output() public autoSavingFieldChange = new EventEmitter<boolean>();

  @ViewChild("f") public form!: NgForm;

  protected readonly URL_VALIDATION_PATTERN =
    CustomFormValidators.URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN;
  private privacyPolicyLinkKeyUpTimeout?: ReturnType<typeof setTimeout>;

  public ngOnInit(): void {
    this.partner.webUrlPrefix = UrlPrefix.Https;
  }

  protected formatWebUrl(): void {
    if (this.partner.webUrl) {
      this.partner.webUrl = this.partner.webUrl.replace(" ", "");
      UrlPrefixes.map((prefix: string) => {
        this.partner.webUrl = this.partner.webUrl.replace(prefix, "");
      });
    }
  }

  protected formatBlogUrl(): void {
    if (this.partner.blogUrl) {
      this.partner.blogUrl = this.partner.blogUrl.replace(" ", "");
      UrlPrefixes.map((prefix: string) => {
        this.partner.blogUrl = this.partner.blogUrl.replace(prefix, "");
      });
    }
  }

  protected handlePrivacyPolicyLinkChanged(): void {
    if (this.partner.privacyPolicyLink.length === 0) {
      this.partner.offerNewsletterSubscription = false;
    }
  }

  protected handleOfferNewsletterSubscriptionChanged(): void {
    this.autoSavingFieldChange.emit(false);
  }

  protected handlePrivacyPolicyLinkFocusOut(): void {
    this.autoSavingFieldChange.emit(false);
  }

  protected handlePrivacyPolicyLinkKeyUp(): void {
    if (this.privacyPolicyLinkKeyUpTimeout) {
      clearTimeout(this.privacyPolicyLinkKeyUpTimeout);
    }

    this.privacyPolicyLinkKeyUpTimeout = setTimeout(() => {
      this.autoSavingFieldChange.emit(false);
    }, 1000);
  }
}
