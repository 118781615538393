import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { EmailValidatorService } from "../../services/email-validator-service";
import { copyToClipboard } from "../../utils/copy-to-clipboard.interactor";
import { NotificationService } from "../../services/notification.service";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: "app-emails-input",
  templateUrl: "./emails-input.component.html",
  styleUrl: "./emails-input.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class EmailsInputComponent {
  @Input({ required: true }) public control!: FormControl<string[]>;
  @Input({ required: true }) public label!: string;
  @Input({ required: true }) public hint!: string;

  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private readonly notificationService: NotificationService,
    private readonly validatorService: EmailValidatorService,
  ) {}

  protected add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const email = (event.value ?? "").toLowerCase().trim();

    if (email) {
      input.value = "";
      this.control.setValue([...this.control.value, email]);
    }
  }

  protected isDuplicateEmail(email: string): boolean {
    return this.control.value.filter((e) => e === email).length > 1;
  }

  protected isValidEmail(email: string): boolean {
    return this.validatorService.validateEmail(email);
  }

  protected async copyToClipboard(email: string): Promise<void> {
    await copyToClipboard(email);
    this.notificationService.info("shared.copiedToClipboard");
  }

  protected remove(email: string): void {
    const emails = [...this.control.value];
    const idx = emails.indexOf(email);

    if (idx >= 0) {
      emails.splice(idx, 1);
      this.control.setValue(emails);
    }
  }
}
