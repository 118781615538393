<h2 mat-dialog-title class="confirm-title">
  {{ "partner.facebookDisconnect.title" | translate }}
  <div class="image-man">
    <img src="../../../../../assets/images/facebook-man-logo.svg" width="88" />
  </div>
</h2>
<mat-dialog-content>
  <p>{{ "partner.facebookDisconnect.description" | translate }}</p>
  <p>
    <strong>{{ "partner.facebookDisconnect.confirm" | translate }}</strong>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" (click)="onActionDisconnect()">
    {{ "partner.facebookDisconnect.yes" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="false">
    {{ "partner.facebookDisconnect.no" | translate }}
  </button>
</mat-dialog-actions>
