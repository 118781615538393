import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UploadFile } from "@angular-ex/uploader";
import { Subscription } from "rxjs";
import { DefaultImageUploadMaxFileSize } from "../../../shared/defaults/file-uploader-max-file-size";
import {
  FileUploaderComponent,
  UploaderAcceptFormat,
} from "../../../shared/components/file-uploader/file-uploader.component";
import { UploaderResponseError } from "../../../shared/components/file-uploader/models/uploader-response-error";
import { Image } from "../../../shared/models/image";
import { Partner } from "../../../shared/models/partner";
import { ImageRatio } from "../../../shared/enums/partner.enums";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { NotificationService } from "../../../shared/services/notification.service";

import { LogoEditionDialogData } from "./logo-edition-dialog/logo-edition-dialog-data";
import {
  LogoEditionDialogComponent,
  LogoEditionResult,
} from "./logo-edition-dialog/logo-edition-dialog.component";

@Component({
  selector: "app-partner-profile-company-files-form",
  templateUrl: "./partner-profile-company-files-form.component.html",
  styleUrl: "./partner-profile-company-files-form.component.scss",
})
export class PartnerProfileCompanyFilesFormComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) public partner!: Partner;
  @Input() public isLoading = false;
  @Input() public isEditForm = false;
  @Input() public isWhiteLabel = false;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public formSubmit = new EventEmitter<void>();

  @ViewChild("logoFileInput")
  protected logoFileInput!: ElementRef;

  @ViewChild(FileUploaderComponent)
  protected fileUploaderComponent!: FileUploaderComponent;

  protected readonly anyFileFormat = UploaderAcceptFormat.Any;
  protected readonly fileUploaderResponseMapper = (res: any) => new Image(res);
  protected readonly MaxFileSize = DefaultImageUploadMaxFileSize;
  protected landscapeLogo?: Image;
  protected squaredLogo?: Image;
  protected uploaderFilesUrl = "";
  protected uploadingLogo = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerService: PartnerService,
    private readonly sanitizer: DomSanitizer,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    try {
      this.uploaderFilesUrl = this.partnerService.getPartnerUploadFileUrl();
    } catch (e) {
      this.uploaderFilesUrl = "";
    }
    this.refreshPartnerLogos();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updateUploaderFilesUrl(): void {
    this.uploaderFilesUrl = this.partnerService.getPartnerUploadFileUrl();
  }

  protected onDeleteFileConfirm(file: Image): void {
    this.dialogService.confirm({
      title: "partner.edit.filesSection.fileDeleteConfirmTitle",
      message: "partner.edit.filesSection.fileDeleteConfirm",
      onConfirm: () => this.deleteFile(file),
    });
  }

  private deleteFile(file: Image): void {
    this.partnerService.deleteFile(file.id).subscribe(() => {
      this.partner.files.some((e, i) => {
        if (e.id === file.id) {
          this.partner.files.splice(i, 1);
          this.partnerService.profileProgressUpdated.emit();
          return true;
        }
      });
    });
  }

  protected logoAddedToQueue(file: UploadFile): void {
    this.logoSelected(file);
  }

  protected handleUploadFilesResponse(file: Image): void {
    this.partner.files.push(file);
  }

  protected isImage(file: Image): boolean {
    return !!(
      file.fileType &&
      (file.fileType === "image/png" ||
        file.fileType === "image/jpg" ||
        file.fileType === "image/jpeg")
    );
  }

  protected onActionCancel(): void {
    this.cancel.emit();
  }

  protected onActionSubmit(): void {
    this.formSubmit.emit();
  }

  protected logoInputSelected(event: any): void {
    const file = event.target.files[0];
    this.logoSelected(file);
  }

  private logoSelected(file: any): void {
    const blob = new Blob([file.nativeFile], { type: file.type });
    const dialog = this.dialog.open(LogoEditionDialogComponent, {
      width: "800px",
      disableClose: true,
      data: new LogoEditionDialogData(
        this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(blob),
        ) as string,
      ),
    });

    this.subscription.add(
      dialog.componentInstance.cancel.subscribe(() => {
        this.logoFileInput.nativeElement.value = "";
        this.fileUploaderComponent.removeFile(file.id);
      }),
    );

    this.subscription.add(
      dialog.componentInstance.logoEdited.subscribe(
        (result: LogoEditionResult) => {
          this.uploadingLogo = true;
          this.uploadLogo(result);
        },
      ),
    );
  }

  private uploadLogo(result: LogoEditionResult): void {
    this.partnerService
      .addLogo(result.file, result.ratio)
      .subscribe((logo: Image) => {
        this.uploadingLogo = false;
        this.partner.logos.push(logo);
        this.refreshPartnerLogos();
        this.partnerService.profileProgressUpdated.emit();
      });
  }

  protected onDeleteLogoConfirm(logo: Image): void {
    this.dialogService.confirm({
      title: "partner.edit.logosSection.logoDeleteConfirmTitle",
      message: "partner.edit.logosSection.logoDeleteConfirm",
      onConfirm: () => this.onDeleteLogo(logo),
    });
  }

  private onDeleteLogo(logo: Image): void {
    this.partnerService.deleteLogo(logo).subscribe(() => {
      this.partner.logos.map((image: Image, i: number) => {
        if (image.id === logo.id) {
          this.partner.logos.splice(i, 1);
          if (logo.ratio === ImageRatio.Squared) {
            this.squaredLogo = undefined;
          } else if (logo.ratio === ImageRatio.Landscape) {
            this.landscapeLogo = undefined;
          }
          this.refreshPartnerLogos();
          this.partnerService.profileProgressUpdated.emit();
        }
      });
    });
  }

  private refreshPartnerLogos(): void {
    this.partner.logos.forEach((logo: Image) => {
      if (logo.ratio === ImageRatio.Squared) {
        this.squaredLogo = logo;
      } else if (logo.ratio === ImageRatio.Landscape) {
        this.landscapeLogo = logo;
      }
    });
  }

  protected fileUploaderErrorHandler(error: UploaderResponseError): void {
    this.notificationService.error(
      this.translateService.instant(error.message, error.interpolateParams),
    );
  }
}
