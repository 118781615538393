import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/api/authentication.service";

@Injectable()
export class PublicAuthGuard implements CanActivate {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  public canActivate(): boolean {
    if (this.authenticationService.isUserLogged) {
      // Redirect to the welcome page if the user is logged in
      this.router.navigate(["welcome"]);
      return false;
    }

    return true;
  }
}
