<div class="partner-dashboard-wrapper">
  <app-page-loader
    *ngIf="loading"
    [text]="'shared.loading' | translate"
  ></app-page-loader>
  <section
    *ngIf="dashboard && dashboard.partnerCurrencies.length > 1"
    class="top-filters"
  >
    <mat-form-field>
      <mat-select
        name="currency"
        [(ngModel)]="currency"
        (ngModelChange)="loadDashboard()"
      >
        <mat-option
          *ngFor="let partnerCurrency of dashboard.partnerCurrencies"
          [value]="partnerCurrency"
          >{{ partnerCurrency }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </section>
  <section *ngIf="dashboard" class="partner-information">
    <h3 class="section-title section-title--first">
      {{ "partner.dashboard.firstLineTitle" | translate }}
    </h3>
    <div class="statistics card">
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalOpenInvitations | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalOpenInvitations" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalJoinedCampaigns | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalJoinedCampaigns" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalDeclinedCampaigns | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalDeclinedCampaigns" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalRunningCampaigns | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalRunningCampaigns" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalCampaignsWithLandingPages | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalCampaignsWithLandingPages" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalCampaignsCompleted | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalCampaignsCompleted" | translate }}
        </p>
      </div>
    </div>
    <h3 class="section-title">
      {{ "partner.dashboard.secondLineTitle" | translate }}
    </h3>
    <div class="statistics card">
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalPublishedPosts | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalPublishedPosts" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalLikesOnPosts | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalLikesOnPosts" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalPublishedAds | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalPublishedAds" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalSpentBudget | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalSpentBudget" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalPostAdViews | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalPostAdViews" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalPeopleReached | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalPeopleReached" | translate }}
        </p>
      </div>
      <div class="statistic-param">
        <p class="amount">
          {{ dashboard.totalLinkClicksGenerated | number: "1.0-2" }}
        </p>
        <p class="type">
          {{ "partner.dashboard.totalLinkClicksGenerated" | translate }}
        </p>
      </div>
    </div>
  </section>
  <section *ngIf="dashboard" class="kpi-chart">
    <div class="card">
      <div class="header">
        <div class="header--title">
          {{ "partner.dashboard.KPIChart.title" | translate | uppercase }}
        </div>
        <div class="filters" *ngIf="kpiChartFilters">
          <button
            *ngFor="let filter of dashboardKPIChartFilters"
            class="filter"
            (click)="setFilter(filter.key)"
            [ngClass]="{
              'filter-active': kpiChartFilters[filter.key],
              'filter-inactive': !kpiChartFilters[filter.key]
            }"
          >
            <div class="bullet" [ngClass]="filter.key"></div>
            {{ filter.text | translate }}
          </button>
        </div>
      </div>
      <div class="chart-date-filters">
        <div></div>
        <div>
          <mat-form-field>
            <mat-label>{{ "shared.startDate" | translate }}</mat-label>
            <input
              matInput
              [value]="kpiChartStartDate | dayjsTransform"
              (dateChange)="validateAndUpdateKPIStartDate($event.value)"
              [min]="kpiChartMinDate | dayjsTransform"
              [max]="kpiChartEndDate | dayjsTransform"
              [matDatepicker]="fromDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "shared.endDate" | translate }}</mat-label>
            <input
              matInput
              [value]="kpiChartEndDate | dayjsTransform"
              (dateChange)="validateAndUpdateKPIEndDate($event.value)"
              [min]="kpiChartStartDate | dayjsTransform"
              [matDatepicker]="toDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="padding: 40px">
        <mat-progress-bar
          *ngIf="loadingKPIChart"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
      <div *ngIf="kpiChartData && !loadingKPIChart" class="chartWrapper">
        <app-chart
          class="chartWrapper__chart"
          [settings]="kpiChartSettings"
        ></app-chart>
      </div>
      <div *ngIf="kpiChartData" class="kpi-chart-footer">
        <div class="statistic-param" *ngIf="kpiChartFilters.publishedPosts">
          <div class="color-bar publishedPosts">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.publishedPosts" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.publishedPosts | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.postViews">
          <div class="color-bar postViews">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.postViews" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.postViews | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.likes">
          <div class="color-bar likes">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.likes" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.likes | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.publishedAds">
          <div class="color-bar publishedAds">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.publishedAds" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.publishedAds | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.spentBudget">
          <div class="color-bar spentBudget">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.spentBudget" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.spentBudget | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.adViews">
          <div class="color-bar adViews">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.adViews" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.adViews | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.reach">
          <div class="color-bar reach">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.reach" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.reach | number: "1.0-2" }}
            </div>
          </div>
        </div>
        <div class="statistic-param" *ngIf="kpiChartFilters.linkClicks">
          <div class="color-bar linkClicks">&nbsp;</div>
          <div class="data">
            <div class="type">
              {{ "partner.dashboard.KPIChart.linkClicks" | translate }}
            </div>
            <div class="amount">
              {{ kpiChartData.linkClicks | number: "1.0-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    *ngIf="dashboard && partner && dashboard.bestPerformingPosts.length > 0"
    class="best-performing-posts"
  >
    <div class="card">
      <h4>
        {{
          "partner.dashboard.bestPerformingPosts.title" | translate | uppercase
        }}
      </h4>
      <div class="column-filter" (clickOutside)="showBestPostsFilter = false">
        <div
          (click)="showBestPostsFilter = !showBestPostsFilter"
          class="button-wrapper button-wrapper--filter-by"
        >
          <div class="box">
            {{ "partner.dashboard.bestPerformingPosts.filtersBy" | translate }}
          </div>
          <div class="arrow-down"></div>
        </div>
        <mat-radio-group (change)="setBestPostsFilter($event.value)">
          <ul [ngClass]="{ visible: showBestPostsFilter }">
            <ng-container>
              <li>
                <mat-radio-button
                  [checked]="true"
                  [value]="PartnerDashboardBestPostsFilters.VIEWS"
                >
                  {{
                    "partner.dashboard.bestPerformingPosts.filterByViews"
                      | translate
                  }}
                </mat-radio-button>
              </li>
              <li>
                <mat-radio-button
                  [value]="PartnerDashboardBestPostsFilters.CLICKS"
                >
                  {{
                    "partner.dashboard.bestPerformingPosts.filterByClicks"
                      | translate
                  }}
                </mat-radio-button>
              </li>
              <li>
                <mat-radio-button
                  [value]="PartnerDashboardBestPostsFilters.LIKES"
                >
                  {{
                    "partner.dashboard.bestPerformingPosts.filterByLikes"
                      | translate
                  }}
                </mat-radio-button>
              </li>
            </ng-container>
          </ul>
        </mat-radio-group>
      </div>
      <div *ngIf="dashboard.bestPerformingPosts.length > 0" class="post-list">
        <div
          class="post-list--item"
          *ngFor="let bestPerformingPostRow of dashboard.bestPerformingPosts"
        >
          <div class="post-list--item-wrap">
            <div class="post-list--item--campaign-title">
              {{ bestPerformingPostRow.campaign.name }}
            </div>
            <app-partner-campaign-post
              *ngIf="bestPerformingPostRow.postLog"
              [partner]="partner"
              [campaign]="bestPerformingPostRow.campaign"
              [type]="PostLogStatus.Published"
              [post]="bestPerformingPostRow.postLog"
            />
          </div>
        </div>
        <div class="section-backdrop" *ngIf="loadingPostList">
          <app-page-loader [text]="'Loading...' | translate" />
        </div>
      </div>
    </div>
  </section>
  <section
    *ngIf="dashboard && dashboard.bestPerformingAds.length > 0"
    class="best-performing-ads"
  >
    <div class="card">
      <h4>
        {{
          "partner.dashboard.bestPerformingAds.title" | translate | uppercase
        }}
      </h4>
      <div class="column-filter" (clickOutside)="showBestAdsFilter = false">
        <div
          (click)="showBestAdsFilter = !showBestAdsFilter"
          class="button-wrapper button-wrapper--filter-by"
        >
          <div class="box">
            {{ "partner.dashboard.bestPerformingAds.filtersBy" | translate }}
          </div>
          <div class="arrow-down"></div>
        </div>
        <mat-radio-group (change)="setBestAdsFilter($event.value)">
          <ul [ngClass]="{ visible: showBestAdsFilter }">
            <ng-container>
              <li>
                <mat-radio-button
                  [checked]="true"
                  [value]="PartnerDashboardBestAdsFilters.IMPRESSIONS"
                >
                  {{
                    "partner.dashboard.bestPerformingAds.filterByImpressions"
                      | translate
                  }}
                </mat-radio-button>
              </li>
              <li>
                <mat-radio-button
                  [value]="PartnerDashboardBestAdsFilters.CLICKS"
                >
                  {{
                    "partner.dashboard.bestPerformingAds.filterByClicks"
                      | translate
                  }}
                </mat-radio-button>
              </li>
              <li>
                <mat-radio-button
                  [value]="PartnerDashboardBestAdsFilters.REACH"
                >
                  {{
                    "partner.dashboard.bestPerformingAds.filterByReach"
                      | translate
                  }}
                </mat-radio-button>
              </li>
            </ng-container>
          </ul>
        </mat-radio-group>
      </div>
      <div *ngIf="dashboard.bestPerformingAds.length > 0" class="post-list">
        <div
          class="post-list--item"
          *ngFor="let bestPerformingAdRow of dashboard.bestPerformingAds"
        >
          <div class="post-list--item-wrap">
            <div class="post-list--item--campaign-title">
              {{ bestPerformingAdRow.campaign.name }}
            </div>
            <app-partner-campaign-post
              *ngIf="bestPerformingAdRow.postLog"
              [partner]="partner"
              [campaign]="bestPerformingAdRow.campaign"
              [type]="PostLogStatus.Published"
              [post]="bestPerformingAdRow.postLog"
            />
          </div>
        </div>
        <div class="section-backdrop" *ngIf="loadingAdList">
          <app-page-loader [text]="'Loading...' | translate" />
        </div>
      </div>
    </div>
  </section>
</div>
