import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-partner-decline-campaign-dialog",
  templateUrl: "./partner-decline-campaign-dialog.component.html",
  styleUrls: ["./partner-decline-campaign-dialog.component.scss"],
})
export class PartnerDeclineCampaignDialogComponent {
  public message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.message = data.message;
  }
}
