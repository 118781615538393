<div class="integration">
  <h2 class="integration-section-title">
    {{ "partner.edit.connectToLinkedInTitle" | translate }}
  </h2>
  <div class="integration-box">
    <div class="integration-box-header">
      <div class="integration-box-header-icons">
        <img src="/assets/images/icons/linkedin/ic_linkedin.svg" />
        <span
          class="integration-box-header-icons-title"
          *ngIf="partner.isConnectedToLinkedIn"
        >
          {{ "partner.edit.connections" | translate }}
        </span>
        <span
          class="integration-box-header-icons-title"
          *ngIf="!partner.isConnectedToLinkedIn"
        >
          {{ "partner.edit.connectToLinkedIn" | translate }}
        </span>
      </div>
      <div
        *ngIf="partner.isConnectedToLinkedIn"
        class="integration-box-header-actions"
      >
        <span
          class="integration-box-header-actions-disconnect"
          (click)="onActionDisconnect()"
        >
          <img src="/assets/images/icons/ic_broken_chains.svg" width="22" />
        </span>
      </div>
    </div>
    <div
      *ngIf="!partner.isConnectedToLinkedIn"
      class="integration-box-not-connected"
    >
      <div *ngIf="connecting" class="integration-box-not-connected-connect-bar">
        <div class="integration-box-not-connected-connecting">
          {{ "partner.edit.connecting" | translate }}
        </div>
        <mat-progress-bar [color]="'accent'" mode="indeterminate">
        </mat-progress-bar>
      </div>
      <div class="integration-box-not-connected-text">
        {{ "partner.edit.connectToLinkedInInfo" | translate }}
      </div>
      <button
        [disabled]="connecting || partner.doNotHaveLinkedinOrganization"
        *ngIf="!partner.isConnectedToLinkedIn"
        class="round-button round-button--grey integration-box-not-connected-button"
        (click)="openLinkedInConnectDialog()"
      >
        {{ "partner.edit.connect" | translate }}
      </button>
      <div class="integration-box-not-connected-checkbox">
        <mat-checkbox
          [disabled]="connecting"
          name="doNotHaveLinkedinOrganization"
          [(ngModel)]="partner.doNotHaveLinkedinOrganization"
          (change)="linkedInChange.emit()"
        >
          {{ "partner.edit.doNotHaveLinkedInPage" | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div *ngIf="partner.isConnectedToLinkedIn" class="integration-box-linkedin">
      <div class="integration-box-linkedin-connected">
        <div class="integration-box-linkedin-connected-page">
          <div class="integration-box-linkedin-connected-page-name">
            <span *ngIf="partner.linkedinOrganizationName">
              {{ partner.linkedinOrganizationName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
