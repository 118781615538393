import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BasicPartnerInfo } from "../../../../shared/models/BasicPartnerInfo";
import { Partner } from "../../../../shared/models/partner";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PartnerShareFacebookConnectionDialogData } from "./partner-share-facebook-connection-dialog-data";
import { PartnerShareFacebookConnectionListRow } from "./partner-share-facebook-connection-list-row";

@Component({
  selector: "app-partner-share-facebook-connection-dialog",
  templateUrl: "./partner-share-facebook-connection-dialog.component.html",
  styleUrls: ["./partner-share-facebook-connection-dialog.component.scss"],
})
export class PartnerShareFacebookConnectionDialogComponent implements OnInit {
  public partners: BasicPartnerInfo[] = [];
  public partnersArray: PartnerShareFacebookConnectionListRow[] = [];
  public partner: Partner;
  public loading = false;

  constructor(
    private readonly dialogRef: MatDialogRef<PartnerShareFacebookConnectionDialogComponent>,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: PartnerShareFacebookConnectionDialogData,
  ) {
    this.partner = data.partner;
  }

  public ngOnInit(): void {
    this.getPartners();
  }

  private getPartners(): void {
    this.loading = true;
    this.partnerService.getPartners(0, 1000).subscribe(
      (partners: BasicPartnerInfo[]) => {
        this.partners = partners;
        this.partnersArray = this.partners
          .filter((partner) => this.partner.id !== partner.id)
          .map(
            (partner) =>
              new PartnerShareFacebookConnectionListRow(
                partner.id,
                partner.companyName,
                this.partner.sharedFacebookConnectionPartnerIds.includes(
                  +partner.id,
                ),
              ),
          );

        this.loading = false;
      },
      () => {
        this.loading = false;
        this.notificationService.error("shared.errorLoadingTheList");
      },
    );
  }

  public shareFacebookConnection(): void {
    this.loading = true;

    const selectedPartnerIds = this.partnersArray
      .filter((partner) => partner.selected)
      .map((partner) => partner.id);

    this.partnerService.shareFacebookConnection(selectedPartnerIds).subscribe(
      () => {
        this.loading = false;
        this.partner.sharedFacebookConnectionPartnerIds = selectedPartnerIds;
        this.partnerService.forcePartnerListReload.emit();
        this.dialogRef.close(true);
        this.notificationService.success("shared.changesSavedSuccessfully");
      },
      () => {
        this.loading = false;
        this.notificationService.error("shared.errorSavingChanges");
      },
    );
  }
}
