import { NgModule } from "@angular/core";

import { AuthGuard } from "./auth.guard";
import { BrowserDetectionGuard } from "./browser-detection.guard";
import { CanDeactivateGuard } from "./can-deactivate.guard";
import { PublicAuthGuard } from "./public-auth.guard";

@NgModule({
  providers: [
    AuthGuard,
    BrowserDetectionGuard,
    CanDeactivateGuard,
    PublicAuthGuard,
  ],
})
export class SharedGuardsModule {}
