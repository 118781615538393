<div class="post post-{{ post.id }}">
  <app-generic-post-mock-up [post]="post" />
  <app-campaign-post-stats
    class="post-stats-list"
    [type]="type"
    [campaign]="campaign"
    [post]="post"
    (openPromoteAdDialog)="openPromoteFacebookPostDialog($event)"
    (openAdConfigDialog)="openAdConfigDialog($event)"
    (openEditPostDialog)="showEditPostModal($event)"
    (openCancelPostDialog)="showCancelPostModal($event)"
  />
</div>
