import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthenticationService } from "../shared/services/api/authentication.service";
import { NotificationService } from "../shared/services/notification.service";

export const LoggedInAsPartnerGuard = (): CanActivateFn => () => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);
  const isPartner = authenticationService.isRolePartnerOrAdmin();

  if (!isPartner) {
    notificationService.error("shared.accessForbidden");
    router.navigate(["welcome"]); // TODO: change to 404 page
    return false;
  }

  return true;
};
