import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { Partner } from "../../shared/models/partner";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { User } from "../../shared/models/user";
import { DialogService } from "../../shared/services/dialog.service";

@Component({
  selector: "app-user-management-form",
  templateUrl: "./user-management-form.component.html",
  styleUrl: "./user-management-form.component.scss",
})
export class UserManagementFormComponent {
  @Input({ required: true }) public partner!: Partner;
  @Input() public isEditForm = false;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public finishEdition = new EventEmitter<void>();

  @ViewChild(NgForm) protected umForm!: NgForm;

  protected loggedInUserName: string;
  protected userEmail!: string;
  protected saving = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
  ) {
    this.loggedInUserName = this.authenticationService.getUserName() ?? "";
  }

  private clearForm(): void {
    this.umForm.resetForm();
  }

  protected async addUser(email: string): Promise<void> {
    this.saving = true;

    try {
      const user = await lastValueFrom(
        this.userService.createPartnerUser(this.partner.id, email),
      );
      this.partner.users.push(user);
      this.notificationService.info("partner.userManagement.userHasBeenAdded");
      this.saving = false;
      this.clearForm();
    } catch (e: any) {
      const errorKey = e?.error?.error?.key;
      if (errorKey === "USER_EXISTS") {
        this.saving = false;
        this.notificationService.error(
          "partner.userManagement.userAlreadyExists",
        );
      } else if (errorKey === "USER_NOT_FOUND") {
        this.inviteUser(email);
      }
      return;
    }
  }

  private inviteUser(email: string): void {
    this.userService
      .invitePartnerUser(this.partner.id, { email: email })
      .subscribe({
        next: (invitedUser: User) => {
          this.partner.users.push(invitedUser);
          this.clearForm();
          this.saving = false;
          this.notificationService.success(
            "partner.userManagement.userInvited",
          );
        },
        error: () => {
          this.saving = false;
          this.notificationService.error(
            "partner.userManagement.errorInvitingUser",
          );
        },
      });
  }

  private removeUser(user: User): void {
    this.userService.deletePartnerUser(this.partner.id, user.id).subscribe({
      next: () => {
        this.partner.users = this.partner.users.filter((u) => u.id !== user.id);
        this.notificationService.info("partner.userManagement.userRemoved");
      },
      error: () => {
        this.notificationService.error(
          "partner.userManagement.errorRemovingUser",
        );
      },
    });
  }

  protected async confirmRemoval(user: User): Promise<void> {
    if (user.email === this.loggedInUserName) {
      return;
    }

    const confirmed = await this.dialogService.showConfirmRemoveUser(
      user.email,
    );

    if (confirmed) {
      this.removeUser(user);
    }
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  protected async onFinish(email: string): Promise<void> {
    if (email) {
      await this.addUser(email);
      setTimeout(() => {
        this.finishEdition.emit();
      }, 1500);
    } else {
      this.finishEdition.emit();
    }
  }
}
