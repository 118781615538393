<h2 mat-dialog-title class="confirm-title">
  {{ "partner.googleDisconnect.title" | translate }}
  <div class="image-man">
    <img src="../../../../../assets/images/google-man-logo.svg" width="88" />
  </div>
</h2>
<mat-dialog-content>
  <p>{{ "partner.googleDisconnect.description" | translate }}</p>
  <p>
    <strong>{{ "partner.googleDisconnect.confirm" | translate }}</strong>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="true">
    {{ "partner.googleDisconnect.yes" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="false">
    {{ "partner.googleDisconnect.no" | translate }}
  </button>
</mat-dialog-actions>
