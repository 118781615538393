<div class="container" *ngIf="isProgressBarVisible()">
  <p>
    <strong>{{ "partner.profileProgress.title" | translate }}</strong>
    <img
      class="close-banner"
      src="/assets/images/icons/ic_close_banner.svg"
      title="{{ 'shared.close' | translate }}"
      (click)="dismissProgressBar()"
    />
  </p>
  <ul>
    <li class="progress-bar-wrapper">
      <span class="progress-bar">
        <span
          class="progress-bar-percentage"
          [ngStyle]="
            profileProgressPercentage && {
              width: profileProgressPercentage + '%'
            }
          "
        ></span>
      </span>
      <span class="progress-percentage">{{ profileProgressPercentage }}%</span>
    </li>
    <li
      class="task"
      [ngClass]="{ 'task-completed': partner.areGoogleAdsAuthorized }"
    >
      <a
        [routerLink]="[PartnerUrl.Profile(partner.id)]"
        (click)="routeWithData([PartnerUrl.Profile(partner.id)], 2)"
      >
        {{ "partner.profileProgress.googleAccountConnected" | translate }}
      </a>
    </li>
    <li class="task" [ngClass]="{ 'task-completed': partner.isConnectedToFB }">
      <a
        [routerLink]="[PartnerUrl.Profile(partner.id)]"
        (click)="routeWithData([PartnerUrl.Profile(partner.id)], 2)"
      >
        {{ "partner.profileProgress.facebookPageConnected" | translate }}
      </a>
    </li>
    <li
      class="task"
      [ngClass]="{ 'task-completed': partner.isAuthorizedFBAds }"
    >
      <a
        [routerLink]="[PartnerUrl.Profile(partner.id)]"
        (click)="routeWithData([PartnerUrl.Profile(partner.id)], 2)"
      >
        {{ "partner.profileProgress.facebookAdRightsTransferred" | translate }}
      </a>
    </li>
    <li class="task" [ngClass]="{ 'task-completed': partner.hasUploadedFiles }">
      <a
        [routerLink]="[PartnerUrl.Profile(partner.id)]"
        (click)="routeWithData([PartnerUrl.Profile(partner.id)], 3)"
      >
        {{ "partner.profileProgress.companyLogoUploaded" | translate }}
      </a>
    </li>
  </ul>
</div>
