<app-partner-profile-progress *ngIf="partner" [partner]="partner" />
<div class="edit-partner-container" *ngIf="partner">
  <mat-tab-group
    [selectedIndex]="selectedIndex"
    (selectedIndexChange)="onSelectedTabChange($event)"
    mat-stretch-tabs="false"
  >
    <!-- Company Info Tab -->
    <mat-tab label="{{ 'partner.edit.companyInfoTab' | translate }}">
      <app-partner-profile-company-information-form
        *ngIf="appData && partner"
        [partner]="partner"
        [appData]="appData"
        [submitting]="submitting"
        (formSubmit)="submit(PartnerEditionStep.Contact)"
      />
    </mat-tab>
    <!-- Contact Info Tab -->
    <mat-tab
      label="{{ 'partner.edit.contactInfoTab' | translate }}"
      [disabled]="PartnerEditionStep.Contact > maxNavigableStep()"
    >
      <app-partner-profile-contact-information-form
        *ngIf="appData && partner"
        [partner]="partner"
        [appData]="appData"
        [isEditForm]="true"
        (formSubmit)="submit(PartnerEditionStep.Social, $event)"
      />
    </mat-tab>
    <!-- Social Accounts Tab -->
    <mat-tab
      label="{{ 'partner.edit.socialAccountsTab' | translate }}"
      [disabled]="PartnerEditionStep.Social > maxNavigableStep()"
    >
      <app-partner-profile-social-links-form
        [partner]="partner"
        [isEditForm]="true"
        (formSubmit)="submit(PartnerEditionStep.Logo)"
      />
    </mat-tab>
    <!-- Company files Tab -->
    <mat-tab
      label="{{ 'partner.edit.companyFilesTab' | translate }}"
      [disabled]="PartnerEditionStep.Logo > maxNavigableStep()"
    >
      <app-partner-profile-company-files-form
        [partner]="partner"
        [isEditForm]="true"
        (formSubmit)="!isWhiteLabel ? goToStepFive() : finishEditing()"
      />
    </mat-tab>
    <!-- User Management Tab -->
    <mat-tab
      *ngIf="!isWhiteLabel"
      label="{{ 'partner.edit.userManagementTab' | translate }}"
      [disabled]="PartnerEditionStep.UserManagement > maxNavigableStep()"
    >
      <app-user-management-form
        [partner]="partner"
        [isEditForm]="true"
        (cancel)="selectedIndex = 3"
        (finishEdition)="finishEditing()"
      />
    </mat-tab>
  </mat-tab-group>
</div>
