import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { AppData } from "../../../shared/models/appData";
import { Partner } from "../../../shared/models/partner";
import { AppDataService } from "../../../shared/services/api/app-data.service";
import { isWhiteLabel } from "../../../shared/services/api/authentication.service";
import { PartnerAccessRequestService } from "../../../shared/services/api/partner-access-request.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { PartnerAccountExistsResponse } from "../../../shared/services/responses/partner-account-exists-response";
import { PartnerUrl } from "../../partner.url";
import { PartnerProfileCompanyFilesFormComponent } from "../partner-profile-company-files-form/partner-profile-company-files-form.component";
import { CreatePartnerAccountExistsDialogData } from "./create-partner-account-exists-dialog/create-partner-account-exists-dialog-data";
import { CreatePartnerAccountExistsDialogComponent } from "./create-partner-account-exists-dialog/create-partner-account-exists-dialog.component";

@Component({
  selector: "app-create-partner",
  templateUrl: "./create-partner.component.html",
  styleUrls: ["./create-partner.component.scss"],
  providers: [PartnerAccessRequestService],
})
export class CreatePartnerComponent implements OnInit {
  @ViewChild(PartnerProfileCompanyFilesFormComponent)
  protected partnerProfileCompanyFilesFormComponent!: PartnerProfileCompanyFilesFormComponent;

  protected appData!: AppData;
  protected isLoading = false;
  protected partner = Partner.create();
  protected tabIndex = 0;
  protected readonly isWhiteLabel = isWhiteLabel();

  constructor(
    private readonly appDataService: AppDataService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly partnerAccessRequestService: PartnerAccessRequestService,
    private readonly partnerService: PartnerService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.appData = await this.appDataService.get();
  }

  protected goToOverview(): void {
    this.router.navigate(["/"]);
  }

  protected goToStep(tabIndex: number): void {
    this.tabIndex = tabIndex;
  }

  protected goToPreviousStep(): void {
    if (this.tabIndex > 0) {
      this.tabIndex--;
    }
  }

  protected goToStepOne(): void {
    this.tabIndex = 0;
  }

  protected goToStepTwo(): void {
    if (this.isPartnerCreated()) {
      this.updatePartner();
    } else {
      this.createPartnerValidation();
      return;
    }

    this.tabIndex = 1;
  }

  protected goToStepThree(updatedPartner?: Partner): void {
    if (updatedPartner) {
      this.partner = updatedPartner;
    }

    if (this.isPartnerCreated()) {
      this.updatePartner();
    }

    this.tabIndex = 2;
  }

  protected goToStepFour(goToNextStep = true): void {
    if (this.isPartnerCreated()) {
      this.updatePartner();
    } else {
      this.createPartnerValidation();
    }

    if (goToNextStep) {
      this.tabIndex = 3;
    }
  }

  protected goToStepFive(): void {
    if (this.isPartnerCreated()) {
      this.updatePartner();
    }
    this.tabIndex = 4;
  }

  private showAccountExistsModal(
    accountExistsResponse: PartnerAccountExistsResponse[],
  ): void {
    const createPartnerAccountExistsDialogRef = this.dialog.open(
      CreatePartnerAccountExistsDialogComponent,
      {
        width: "810px",
        data: new CreatePartnerAccountExistsDialogData(accountExistsResponse),
        disableClose: true,
      },
    );

    createPartnerAccountExistsDialogRef.componentInstance.sameAccountNo.subscribe(
      () => {
        createPartnerAccountExistsDialogRef.close();
        this.createPartner();
      },
    );

    createPartnerAccountExistsDialogRef.componentInstance.sameAccountYes.subscribe(
      (partnerAccountId: number) => {
        createPartnerAccountExistsDialogRef.close();
        this.showSameAccountVerificationEmailSent(partnerAccountId);
        this.isLoading = false;
      },
    );
  }

  private showSameAccountVerificationEmailSent(partnerAccountId: number): void {
    this.partnerAccessRequestService
      .requestPartnerAccountAccess(partnerAccountId)
      .subscribe({
        next: () => {
          this.notificationService.success(
            "partner.edit.accountAccessRequestedSubtitle",
            "partner.edit.accountAccessRequestedTitle",
          );
          this.router.navigate(["/welcome"]);
        },
        error: () =>
          this.notificationService.error("shared.errorSavingChanges"),
      });
  }

  private createPartnerValidation(): void {
    if (this.isPartnerCreated()) {
      return;
    }

    this.isLoading = true;

    this.partnerAccessRequestService
      .checkAccountExists(
        this.partner.companyName,
        this.partner.companyAdditionalInformation,
        this.partner.billingAddress.streetAndNumber,
        this.partner.billingAddress.postalCode,
        this.partner.billingAddress.city,
        this.partner.billingAddress.country,
        this.partner.vatNumber,
      )
      .subscribe({
        next: (accountExistsResponses) => {
          if (accountExistsResponses.length > 0) {
            this.showAccountExistsModal(accountExistsResponses);
          } else {
            this.createPartner();
          }
        },
        error: (err) => {
          const error = err.error;
          this.notificationService.errorDialog(
            this.translateService.instant("partner.edit.errorCreating") +
              " " +
              error.message,
            this.translateService.instant("Error: ") + error.name,
          );

          this.isLoading = false;
        },
      });
  }

  private createPartner(): void {
    this.partnerService.create(this.partner).subscribe({
      next: (createdPartner) => {
        this.partner = createdPartner;
        this.notificationService.success("shared.partnerCreatedSuccessfully");
        this.tabIndex = 1;
        this.partnerProfileCompanyFilesFormComponent.updateUploaderFilesUrl();
      },
      error: () =>
        this.notificationService.errorDialog("partner.edit.errorCreating", ""),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private isPartnerCreated(): boolean {
    return Boolean(this.partner.id);
  }

  private updatePartner(): void {
    this.isLoading = true;

    this.partnerService.update(this.partner).subscribe({
      next: (result: Partner) => {
        this.partner = result;
        this.notificationService.success("shared.changesSavedSuccessfully");
      },
      error: () =>
        this.notificationService.error("shared.changesCouldNotBeSaved"),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  protected finishEditing(): void {
    this.partnerService.setCurrentPartner(this.partner);
    this.notificationService.success("shared.changesSavedSuccessfully");
    this.router.navigate([PartnerUrl.Home(this.partner.id)]);
  }
}
