import { Component, Input, OnInit } from "@angular/core";

import { Partner } from "../../models/partner";
import { AppDataCountry } from "../../models/appData";
import { Address, AddressType } from "../../models/address";
import { MatIconModule } from "@angular/material/icon";
import { NgFor, NgIf } from "@angular/common";
import { AddressBoxComponent } from "../address-box/address-box.component";

@Component({
  selector: "app-address-slider",
  templateUrl: "./address-slider.component.html",
  styleUrl: "./address-slider.component.scss",
  standalone: true,
  imports: [AddressBoxComponent, MatIconModule, NgIf, NgFor],
})
export class AddressSliderComponent implements OnInit {
  @Input({ required: true }) public addressType!: AddressType;
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public countries!: AppDataCountry[];

  protected sliderAddressIndex = 0;

  public ngOnInit(): void {
    this.getNumberOfSlides();
  }

  private getNumberOfSlides(): number {
    if (window.innerWidth >= 1440) {
      return 4;
    }
    return 3;
  }

  protected getAddressToDisplay(): Address[] {
    const sourceAddresses = this.getPartnerAddresses();
    const amountToDisplay = this.getNumberOfSlides();
    const compensation = sourceAddresses.length - this.sliderAddressIndex;
    let startFrom = this.sliderAddressIndex;

    if (compensation < amountToDisplay) {
      startFrom -= amountToDisplay - compensation;
    }

    return sourceAddresses.filter(
      (_address, index) =>
        index >= startFrom && index < startFrom + amountToDisplay,
    );
  }

  private getPartnerAddresses(): Address[] {
    return this.partner.addresses.filter(
      (a: Address) => a.type === this.addressType,
    );
  }

  protected showNextSliderIcon(): boolean {
    return (
      this.sliderAddressIndex + this.getNumberOfSlides() <
      this.getPartnerAddresses().length
    );
  }

  protected nextSliderAddress(): void {
    if (
      this.sliderAddressIndex + this.getNumberOfSlides() <
      this.getPartnerAddresses().length
    ) {
      this.sliderAddressIndex++;
    }
  }

  protected previousSliderAddress(): void {
    if (this.sliderAddressIndex > 0) {
      this.sliderAddressIndex--;
    }
  }
}
