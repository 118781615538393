import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { lastValueFrom } from "rxjs";
import { ConnectToLinkedInInteractor } from "../../../../shared/interactors/linkedin/connect-to-linkedin.interactor";
import { Partner } from "../../../../shared/models/partner";
import { LinkedInService } from "../../../../shared/services/api/linkedin.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { StorageKeys } from "../../../../shared/services/local-storage.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  selector: "app-linkedin-connection-box",
  templateUrl: "./linkedin-connection-box.component.html",
  styleUrl: "./linkedin-connection-box.component.scss",
})
export class LinkedinConnectionBoxComponent implements OnDestroy {
  @Input({ required: true }) public partner!: Partner;

  @Output() public linkedInChange = new EventEmitter<void>();

  protected connecting = false;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly linkedInService: LinkedInService,
    private readonly connectToLinkedin: ConnectToLinkedInInteractor,
  ) {}

  public ngOnDestroy(): void {
    window.removeEventListener("storage", this.handleStorageEvent);
  }

  /** Defining the handler as property instead of a method, so "this" is bound,
   * and we can use the same reference both to add and remove  event listeners.
   * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions#cannot_be_used_as_methods
   * */
  private handleStorageEvent = async (event: StorageEvent): Promise<void> => {
    if (
      event.key !== StorageKeys.LinkedInConnection ||
      event.newValue === null
    ) {
      return;
    }
    if (await this.connectToLinkedin.execute(this.partner.id)) {
      this.linkedInChange.emit();
    } else {
      this.notificationService.error("linkedinConnectDialog.error");
    }
  };

  protected openLinkedInConnectDialog(): void {
    window.addEventListener("storage", this.handleStorageEvent);

    this.linkedInService.getAccessUrl(this.partner.id).then((url) => {
      window.open(url, "_blank");
    });
  }

  protected async onActionDisconnect(): Promise<void> {
    const disconnect = await this.partnerDialogService.showLinkedInDisconnect();

    if (disconnect) {
      await this.linkedInService.disconnect(this.partner.id);

      const partner = await lastValueFrom(
        this.partnerService.fetchCurrentPartner(),
      );

      this.partner = partner!;
    }
  }
}
