import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PartnerAccountExistsResponse } from "../../../../shared/services/responses/partner-account-exists-response";
import { CreatePartnerAccountExistsDialogData } from "./create-partner-account-exists-dialog-data";

@Component({
  selector: "app-create-partner-account-exists-dialog",
  templateUrl: "./create-partner-account-exists-dialog.component.html",
  styleUrls: ["./create-partner-account-exists-dialog.component.scss"],
})
export class CreatePartnerAccountExistsDialogComponent {
  public readonly sameAccountYes = new EventEmitter<number>();
  public readonly sameAccountNo = new EventEmitter<void>();

  public partnerAccountExistsResponses: PartnerAccountExistsResponse[];
  public selectedAccountId!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CreatePartnerAccountExistsDialogData,
  ) {
    this.partnerAccountExistsResponses = data.partnerAccountExistsResponses;

    if (this.partnerAccountExistsResponses[0]) {
      this.selectedAccountId = this.partnerAccountExistsResponses[0].id;
    }
  }

  protected answerNo(): void {
    this.sameAccountNo.emit();
  }

  protected answerYes(): void {
    this.sameAccountYes.emit(this.selectedAccountId);
  }

  protected getSingleAccount(): PartnerAccountExistsResponse | undefined {
    if (this.partnerAccountExistsResponses[0]) {
      return this.partnerAccountExistsResponses[0];
    }
  }
}
