import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";

@Injectable()
export class BrowserDetectionGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (this.isIE()) {
      this.router.navigate(["/unsupported-browser"]);
      return false;
    }
    return true;
  }

  private isIE() {
    const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }
}
