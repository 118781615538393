import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import dayjs from "dayjs";

import { Partner } from "../../shared/models/partner";
import { ChartData } from "../../shared/charts/models/chart-data.model";
import { LineChartSettings } from "../../shared/charts/models/chart-settings.model";
import { CampaignCurrency } from "../../shared/enums/campaign.enums";
import { PostLogStatus } from "../../shared/enums/campaignPost.enums";
import { PartnerDashboard } from "../../shared/models/partnerDashboard";
import { ChartDataGroup } from "../../shared/charts/models/chart-data-group.model";
import { PartnerService } from "../../shared/services/api/partner.service";
import { NotificationService } from "../../shared/services/notification.service";
import { PartnerKpiChart } from "../../shared/models/partner-kpi-chart";
import { partnerDashboardAllKpisChartSettings } from "./charts/partner-dashboard-all-kpis-chart-settings";
import { partnerDashboardAllKpisChartDataSettings } from "./charts/partner-dashboard-all-kpis-chart-data-settings";

export class KPIChartFilters {
  constructor(
    public publishedPosts: boolean,
    public publishedAds: boolean,
    public likes: boolean,
    public spentBudget: boolean,
    public postViews: boolean,
    public adViews: boolean,
    public reach: boolean,
    public linkClicks: boolean,
  ) {}
}

enum PartnerDashboardBestPostsFilters {
  VIEWS = "POST_VIEWS",
  LIKES = "POST_LIKES",
  CLICKS = "POST_LINK_CLICKS",
}

enum PartnerDashboardBestAdsFilters {
  IMPRESSIONS = "AD_IMPRESSIONS",
  REACH = "AD_REACH",
  CLICKS = "AD_CLICKS",
}

interface FilterButton {
  key: keyof KPIChartFilters;
  text: string;
}
@Component({
  selector: "app-partner-dashboard",
  templateUrl: "./partner-dashboard.component.html",
  styleUrl: "./partner-dashboard.component.scss",
})
export class PartnerDashboardComponent implements OnInit {
  protected readonly PartnerDashboardBestPostsFilters =
    PartnerDashboardBestPostsFilters;
  protected readonly PartnerDashboardBestAdsFilters =
    PartnerDashboardBestAdsFilters;
  protected readonly PostLogStatus = PostLogStatus;
  protected readonly dashboardKPIChartFilters: FilterButton[] = [
    {
      key: "publishedPosts",
      text: "partner.dashboard.KPIChart.publishedPosts",
    },
    {
      key: "postViews",
      text: "partner.dashboard.KPIChart.postViews",
    },
    {
      key: "likes",
      text: "partner.dashboard.KPIChart.likes",
    },
    {
      key: "publishedAds",
      text: "partner.dashboard.KPIChart.publishedAds",
    },
    {
      key: "spentBudget",
      text: "partner.dashboard.KPIChart.spentBudget",
    },
    {
      key: "adViews",
      text: "partner.dashboard.KPIChart.adViews",
    },
    {
      key: "reach",
      text: "partner.dashboard.KPIChart.reach",
    },
    {
      key: "linkClicks",
      text: "partner.dashboard.KPIChart.linkClicks",
    },
  ];

  protected currency: CampaignCurrency = CampaignCurrency.EUR;
  protected dashboard?: PartnerDashboard;
  protected kpiChartData?: PartnerKpiChart;
  protected kpiChartEndDate!: Date;
  protected kpiChartFilters = new KPIChartFilters(
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  );
  protected kpiChartMinDate!: Date;
  protected kpiChartSettings!: LineChartSettings;
  protected kpiChartStartDate!: Date;
  protected loading = true;
  protected loadingAdList!: boolean;
  protected loadingKPIChart = true;
  protected loadingPostList!: boolean;
  protected partner!: Partner;
  protected showBestAdsFilter = false;
  protected showBestPostsFilter = false;

  private previousKpiChartStartDate!: Date;
  private previousKpiChartEndDate!: Date;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.kpiChartStartDate = new Date();
    this.kpiChartStartDate = dayjs(this.kpiChartStartDate)
      .subtract(1, "months")
      .toDate();
    this.kpiChartStartDate = dayjs(this.kpiChartStartDate)
      .add(1, "days")
      .toDate();
    this.kpiChartEndDate = new Date();
    this.kpiChartEndDate = dayjs(this.kpiChartEndDate).add(1, "days").toDate();
    // Metrics started been tracked on Nov 2019
    this.kpiChartMinDate = dayjs(1575158400000).toDate();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.startDate) {
        this.kpiChartStartDate = dayjs(params.startDate, "YYYY-MM-DD").toDate();
        this.kpiChartStartDate = dayjs(this.kpiChartStartDate).hour(0).toDate();
        this.kpiChartStartDate = dayjs(this.kpiChartStartDate)
          .minute(0)
          .toDate();
      }
      if (params.endDate) {
        this.kpiChartEndDate = dayjs(params.endDate, "YYYY-MM-DD").toDate();
        this.kpiChartEndDate = dayjs(this.kpiChartEndDate).hour(0).toDate();
        this.kpiChartEndDate = dayjs(this.kpiChartEndDate).minute(0).toDate();
      }
    });
  }

  public ngOnInit(): void {
    this.partner = this.partnerService.currentPartner;
    this.currency = this.partner.currency as CampaignCurrency;
    this.loadDashboard();
  }

  protected loadDashboard(postsFilter?: string, adsFilter?: string): void {
    this.partnerService
      .getDashboard(
        postsFilter ?? PartnerDashboardBestPostsFilters.VIEWS,
        adsFilter ?? PartnerDashboardBestAdsFilters.IMPRESSIONS,
        this.currency,
      )
      .subscribe({
        next: (dashboard) => {
          this.dashboard = dashboard;
          this.currency = dashboard.currency;
          this.loadDashboardKPIChart();
        },
        error: () => {
          this.notificationService.error(
            "partner.dashboard.errorLoadingDashboard",
          );
        },
        complete: () => {
          this.loading = false;
          this.loadingPostList = false;
          this.loadingAdList = false;
        },
      });
  }

  private loadDashboardKPIChart(): void {
    this.partnerService
      .getDashboardKPIChart(
        this.kpiChartStartDate,
        this.kpiChartEndDate,
        this.currency,
      )
      .subscribe({
        next: (kpiChartData) => {
          this.kpiChartData = kpiChartData;
          this.loadChartjs();
          this.loading = false;
          this.loadingKPIChart = false;
        },
        error: () => {
          this.loading = false;
          this.loadingKPIChart = false;
          this.notificationService.error(
            "partner.dashboard.errorLoadingDashboard",
          );
        },
      });
  }

  protected setFilter(id: keyof KPIChartFilters): void {
    if (id in this.kpiChartFilters) {
      this.kpiChartFilters[id] = !this.kpiChartFilters[id];
      this.loadChartjs();
    }
  }

  protected validateAndUpdateKPIStartDate(newStartDate?: dayjs.Dayjs): void {
    if (this.validateKPIDates(newStartDate?.toDate(), this.kpiChartEndDate)) {
      this.kpiChartStartDate = newStartDate!.toDate();
      this.previousKpiChartStartDate = newStartDate!.toDate();
      this.loadingKPIChart = true;
      this.loadDashboardKPIChart();
    }
  }

  protected validateAndUpdateKPIEndDate(newEndDate?: dayjs.Dayjs): void {
    if (this.validateKPIDates(this.kpiChartStartDate, newEndDate?.toDate())) {
      this.kpiChartEndDate = newEndDate!.toDate();
      this.previousKpiChartEndDate = newEndDate!.toDate();
      this.loadingKPIChart = true;
      this.loadDashboardKPIChart();
    }
  }

  private validateKPIDates(startDate?: Date, endDate?: Date): boolean {
    if (!startDate || !endDate) {
      this.notificationService.error("shared.enterAValidDate");
      return false;
    }
    if (startDate >= endDate) {
      this.notificationService.error(
        "partner.dashboard.startAfterEndDateError",
      );
      this.kpiChartStartDate = this.previousKpiChartStartDate;
      this.kpiChartEndDate = this.previousKpiChartEndDate;
      return false;
    }
    return true;
  }

  protected setBestPostsFilter(filter: string): void {
    this.loadingPostList = true;
    this.loadDashboard(filter);
  }

  protected setBestAdsFilter(filter: string): void {
    this.loadingAdList = true;
    this.loadDashboard(undefined, filter);
  }

  private loadChartjs(): void {
    this.kpiChartSettings = new LineChartSettings(
      partnerDashboardAllKpisChartSettings,
      partnerDashboardAllKpisChartDataSettings.map(
        (config) =>
          new ChartDataGroup(
            config.name,
            config.color,
            Object.keys(this.kpiChartData!.series).map(
              (key) =>
                new ChartData(
                  dayjs(key).format("MMM D"),
                  this.kpiChartData!.series[key][config.valueKey],
                ),
            ),
            this.kpiChartFilters[config.filterKey],
          ),
      ),
    );
  }
}
