<div class="row">
  <div class="col width-40">
    <mat-form-field>
      <mat-label>{{
        "partner.edit.phonePrefixPlaceholder" | translate
      }}</mat-label>
      <mat-select
        [formControl]="prefixControl()"
        [disableOptionCentering]="true"
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="prefixFilterControl"
            [placeholderLabel]="
              'partner.edit.phonePrefixPlaceholder' | translate
            "
            [noEntriesFoundLabel]="'partner.edit.phoneNoResults' | translate"
          >
          </ngx-mat-select-search>
        </mat-option>
        @for (prefix of filteredPrefixes(); track prefix.code) {
          <mat-option [value]="prefix.prefix">
            {{ prefix.translatedName }} ({{ prefix.prefix }})
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col width-60">
    <mat-form-field>
      <mat-label>{{ "partner.edit.phonePlaceholder" | translate }}</mat-label>
      <input matInput [formControl]="phoneControl()" type="tel" />
      @if (phoneControl().hasError("required")) {
        <mat-error>
          {{ "partner.edit.phoneRequired" | translate }}
        </mat-error>
      } @else if (phoneControl().hasError("number")) {
        <mat-error>
          {{ "partner.edit.invalidPhoneNumber" | translate }}
        </mat-error>
      }
      @if (hint(); as message) {
        <mat-hint>{{ message | translate }}</mat-hint>
      }
    </mat-form-field>
  </div>
</div>
