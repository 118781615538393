import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  input,
  ViewChild,
} from "@angular/core";
import { NgForm, UntypedFormControl, Validators } from "@angular/forms";
import { AddressType } from "../../../shared/models/address";
import {
  AppData,
  AppDataCurrency,
  AppDataLanguage,
  AppDataSector,
} from "../../../shared/models/appData";
import { Partner } from "../../../shared/models/partner";
import { getTranslatedCountriesSignal } from "../../../shared/services/language.service";
import {
  CapitalizationLimit,
  CustomFormValidators,
  GenericErrorStateMatcher,
} from "../../../shared/validators/custom-form-validators";
import { PartnerDialogService } from "../../partner-dialog.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { markAllAsTouchedAndDirty } from "../../../shared/utils/mark-all-as-touched-and-dirty";

export const COMPANY_ADDITIONAL_INFO_MAX_LENGTH = 25;

@Component({
  selector: "app-partner-profile-company-information-form",
  templateUrl: "./partner-profile-company-information-form.component.html",
  styleUrl: "./partner-profile-company-information-form.component.scss",
})
export class PartnerProfileCompanyInformationFormComponent implements OnInit {
  public appData = input.required<AppData>();
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public submitting!: boolean;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public formSubmit = new EventEmitter<void>();

  public readonly AddressType = AddressType;
  public readonly countries = getTranslatedCountriesSignal(this.appData);
  public readonly COMPANY_ADDITIONAL_INFO_MAX_LENGTH =
    COMPANY_ADDITIONAL_INFO_MAX_LENGTH;
  public sectors!: AppDataSector[];
  public languages!: AppDataLanguage[];
  public currencies!: AppDataCurrency[];
  public storeNameControl!: UntypedFormControl;
  public errorStateMatcher = new GenericErrorStateMatcher();

  @ViewChild("form") public formRef!: NgForm;

  constructor(
    private readonly partnerDialogService: PartnerDialogService,
    private readonly notificationService: NotificationService,
  ) {}

  public ngOnInit(): void {
    this.storeNameControl = new UntypedFormControl(
      this.partner.companyAdditionalInformation,
      [
        Validators.required,
        CustomFormValidators.twoConsecutiveDots,
        CustomFormValidators.uppercaseLimit(CapitalizationLimit.CompanyName),
      ],
    );

    this.sectors = Object.values(this.appData().sectors);
    this.languages = this.appData().languages;
    this.currencies = this.appData().currencies;

    setTimeout(() => {
      if (this.formRef) {
        markAllAsTouchedAndDirty(this.formRef.form);
        this.storeNameControl.markAsDirty();
      }
    });
  }

  protected createAddress(type: AddressType): void {
    this.partnerDialogService.showChooseAddress({
      addresses: this.partner.addresses,
      type: type,
    });
  }

  protected onActionSubmit(): void {
    const isValid = this.formRef.form.valid && this.storeNameControl.valid;

    markAllAsTouchedAndDirty(this.formRef.form);
    this.storeNameControl.markAsDirty();

    if (!isValid) {
      this.notificationService.warning("shared.formIssues");
      return;
    }

    this.partner.companyAdditionalInformation = this.storeNameControl.value;
    this.formSubmit.emit();
  }

  protected compareWithSector(
    item1: AppDataSector,
    item2: AppDataSector,
  ): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }
}
