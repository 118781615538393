import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";

import { Partner } from "../../../../shared/models/partner";
import { PartnerFacebookPageClaimStatus } from "../../../../shared/enums/partner.enums";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { FacebookService } from "../../../../shared/services/facebook.service";

export type FacebookDisconnectDialogData = Partner;

@Component({
  selector: "app-facebook-disconnect-dialog",
  templateUrl: "./facebook-disconnect-dialog.component.html",
  styleUrls: ["../third-party-disconnect-dialog.component.scss"],
})
export class FacebookDisconnectDialogComponent {
  private readonly partner: Partner;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly dialogRef: MatDialogRef<FacebookDisconnectDialogComponent>,
    private readonly facebookService: FacebookService,
    @Inject(MAT_DIALOG_DATA) data: FacebookDisconnectDialogData,
  ) {
    this.partner = data;
  }

  public async onActionDisconnect(): Promise<void> {
    this.partner.hasConnectedToFacebook = false;
    this.partner.facebookPageId = "";
    this.partner.facebookPageName = undefined;
    this.partner.facebookPagePicture = undefined;
    this.partner.facebookPageClaimStatus =
      PartnerFacebookPageClaimStatus.NotClaimed;
    try {
      await Promise.all([
        lastValueFrom(this.partnerService.resetStatus(this.partner)),
        this.facebookService.logout(),
      ]);
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
    }
  }
}
