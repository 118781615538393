<div class="create-partner-container" *ngIf="appData">
  <h1>{{ "partner.edit.newPartner" | translate }}</h1>
  <mat-tab-group
    mat-stretch-tabs="false"
    [selectedIndex]="tabIndex"
    (selectedIndexChange)="goToStep($event)"
  >
    <!-- Company Info Tab -->
    <mat-tab label="1. {{ 'partner.edit.companyInfoTab' | translate }}">
      <app-partner-profile-company-information-form-create
        [partner]="partner"
        [appData]="appData"
        [isLoading]="isLoading"
        (cancel)="goToOverview()"
        (formSubmit)="goToStepTwo()"
      />
    </mat-tab>
    <!-- Contact Info Tab -->
    <mat-tab
      label="2. {{ 'partner.edit.contactInfoTab' | translate }}"
      [disabled]="tabIndex < 1"
    >
      <app-partner-profile-contact-information-form
        [partner]="partner"
        [appData]="appData"
        [isEditForm]="false"
        [isLoading]="isLoading"
        (cancel)="goToPreviousStep()"
        (formSubmit)="goToStepThree($event)"
      />
    </mat-tab>
    <!-- Social Accounts Tab -->
    <mat-tab
      label="3. {{ 'partner.edit.socialAccountsTab' | translate }}"
      [disabled]="tabIndex < 2"
    >
      <app-partner-profile-social-links-form
        [partner]="partner"
        [isLoading]="isLoading"
        (cancel)="goToPreviousStep()"
        (formSubmit)="goToStepFour($event)"
      />
    </mat-tab>
    <!-- Company files Tab -->
    <mat-tab
      label="4. {{ 'partner.edit.companyFilesTab' | translate }}"
      [disabled]="tabIndex < 3"
    >
      <app-partner-profile-company-files-form
        [partner]="partner"
        [isLoading]="isLoading"
        (cancel)="goToPreviousStep()"
        [isWhiteLabel]="isWhiteLabel"
        (formSubmit)="!isWhiteLabel ? goToStepFive() : finishEditing()"
      />
    </mat-tab>
    <!-- User Management Tab -->
    <mat-tab
      *ngIf="!isWhiteLabel"
      label="5. {{ 'partner.edit.userManagementTab' | translate }}"
      [disabled]="tabIndex < 4"
    >
      <app-user-management-form
        [partner]="partner"
        (cancel)="goToPreviousStep()"
        (finishEdition)="finishEditing()"
      />
    </mat-tab>
  </mat-tab-group>
</div>
