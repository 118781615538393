<div class="integrations-google-ads">
  <h2 class="integrations-google-ads-title">
    {{ "partner.edit.googleIntegration" | translate }}
  </h2>
  <div class="integrations-google-ads-box">
    <img src="/assets/images/google-ads-logo-rectangle.svg" />
    <div class="integrations-google-ads-box-text">
      {{ "partner.edit.googleIntegrationInfo" | translate }}
    </div>
    <div class="integrations-google-ads-box-checkbox">
      <mat-checkbox
        (change)="onActionToggleGoogleAdsConnection()"
        name="areGoogleAdsAuthorized"
        [(ngModel)]="partner.areGoogleAdsAuthorized"
      >
        {{ "partner.edit.googleIntegrationSubtitle" | translate }}
      </mat-checkbox>
    </div>
  </div>
</div>
