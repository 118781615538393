import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Partner } from "../../../../shared/models/partner";
import { GoogleDisconnectDialogComponent } from "../../../third-party-connection-dialogs/third-party-disconnect-dialogs/google-disconnect-dialog/google-disconnect-dialog.component";

@Component({
  selector: "app-google-connection-box",
  templateUrl: "./google-connection-box.component.html",
  styleUrl: "./google-connection-box.component.scss",
})
export class GoogleConnectionBoxComponent {
  @Input({ required: true }) public partner!: Partner;

  @Output() public adsAuthorizationChanged = new EventEmitter<void>();

  constructor(private readonly dialog: MatDialog) {}

  protected onActionToggleGoogleAdsConnection(): void {
    if (!this.partner.areGoogleAdsAuthorized) {
      this.showDisconnectGoogleDialog();
    } else {
      this.onEventSubmit();
    }
  }

  private showDisconnectGoogleDialog(): void {
    this.dialog
      .open(GoogleDisconnectDialogComponent, {
        width: "512px",
        data: {
          partner: this.partner,
        },
        panelClass: "overflow-visible-dialog",
      })
      .afterClosed()
      .subscribe((disconnected) => {
        this.partner.areGoogleAdsAuthorized = !disconnected;
        if (disconnected) {
          this.onEventSubmit();
        }
      });
  }

  private onEventSubmit(): void {
    this.adsAuthorizationChanged.emit();
  }
}
