<div class="container">
  <div class="content-wrap">
    <div class="content">
      <div class="content-integrations">
        <app-google-connection-box
          (adsAuthorizationChanged)="saveConnectionChanges()"
          [partner]="partner"
        />
        <app-facebook-connection-box
          (facebookChanged)="saveConnectionChanges()"
          [partner]="partner"
        />
        <app-linkedin-connection-box
          (linkedInChange)="saveConnectionChanges()"
          [partner]="partner"
        />
      </div>
      <div class="content-social-links">
        <app-partner-profile-social-links-section
          [isEditForm]="isEditForm"
          [partner]="partner"
          #linksSection
          (autoSavingFieldChange)="saveConnectionChanges()"
        />
      </div>
    </div>
    <div class="footer">
      <div *ngIf="!isEditForm" class="footer-button-bar-create">
        <button
          class="footer-button-previous round-button round-button--grey"
          type="button"
          (click)="onActionCancel()"
        >
          {{ "shared.previous" | translate }}
        </button>
        <button
          class="footer-button-next round-button round-button--orange"
          (click)="onActionSubmit()"
          [disabled]="isLoading"
        >
          {{ "shared.next" | translate }}
        </button>
      </div>
      <div *ngIf="isEditForm" class="footer-button-bar-edit">
        <button
          class="round-button round-button--orange footer-button-save"
          (click)="onActionSubmit()"
          [disabled]="isLoading"
        >
          {{ "shared.saveAndNext" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
