<h2 mat-dialog-title>{{ "facebookConnectInfo.title" | translate }}</h2>
<mat-dialog-content>
  <h3 style="margin-bottom: 0px; margin-top: 0px">
    {{ "facebookConnectInfo.blockOne" | translate }}
  </h3>
  <div style="font-size: 14px; margin-bottom: 20px">
    {{ "facebookConnectInfo.blockTwo" | translate }}
  </div>
  <p>1. {{ "facebookConnectInfo.blockThree" | translate }}</p>
  <p>2. {{ "facebookConnectInfo.blockThreeExtra" | translate }}</p>
  <p>3. {{ "facebookConnectInfo.blockFour" | translate }}</p>
  <p>4. {{ "facebookConnectInfo.blockFive" | translate }}</p>
  <p>5. {{ "facebookConnectInfo.blockSix" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="button button-orange button-full-width"
    [mat-dialog-close]="true"
  >
    {{ "shared.ok" | translate }}
  </button>
</mat-dialog-actions>
