import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SafeResourceUrl } from "@angular/platform-browser";

import { ICropperResult } from "../../../../shared/components/image-cropper/image-cropper.component";
import { ImageRatio } from "../../../../shared/enums/partner.enums";

import { LogoEditionDialogData } from "./logo-edition-dialog-data";

export class LogoEditionResult {
  file: string;
  ratio: ImageRatio;
  constructor(file: string, ratio: ImageRatio) {
    this.file = file;
    this.ratio = ratio;
  }
}

@Component({
  selector: "app-logo-edition-dialog",
  templateUrl: "./logo-edition-dialog.component.html",
  styleUrl: "./logo-edition-dialog.component.scss",
})
export class LogoEditionDialogComponent {
  @Output() public cancel = new EventEmitter<void>();
  @Output() public logoEdited = new EventEmitter<LogoEditionResult>();

  protected aspectRatio = 1;
  protected backgroundColor = "transparent";
  protected imageDestination: ICropperResult = { image: "", url: "" };
  protected originalImage: SafeResourceUrl;

  constructor(
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: LogoEditionDialogData,
  ) {
    this.originalImage = data.image;
  }

  protected uploadLogo(): void {
    this.logoEdited.emit(
      new LogoEditionResult(
        this.imageDestination.url,
        this.aspectRatio === 1 ? ImageRatio.Squared : ImageRatio.Landscape,
      ),
    );

    this.close();
  }

  protected close(): void {
    this.cancel.emit();
    this.dialog.closeAll();
  }

  protected onImageChanged(result: ICropperResult): void {
    this.imageDestination = result;
  }
}
