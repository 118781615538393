import { FormGroup } from "@angular/forms";

export function markAllAsTouchedAndDirty(form: FormGroup): void {
  form.markAllAsTouched();

  Object.values(form.controls).forEach((control) => {
    control.markAsDirty();
    control.markAsTouched();
  });
}
