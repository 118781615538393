import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/api/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  public canActivate(): boolean {
    if (this.authenticationService.isUserLogged) {
      return true;
    }

    this.router.navigate(["/login"]);
    return false;
  }
}
