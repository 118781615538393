<div class="address-slider">
  <div class="address-slider-control">
    <a *ngIf="sliderAddressIndex >= 1" (click)="previousSliderAddress()">
      <mat-icon class="address-slider-arrow" class="icon-refer"
        >arrow_back_ios</mat-icon
      >
    </a>
  </div>
  <div
    class="address-slider-content"
    *ngFor="let address of getAddressToDisplay()"
  >
    <app-address-box
      *ngIf="address && partner && countries"
      [address]="address"
      [countries]="countries"
      [allowEdition]="true"
      [partner]="partner"
      mode="changeable"
      boxSize="medium"
      [type]="addressType"
      fullHeight
    />
  </div>
  <div class="address-slider-control">
    <a *ngIf="showNextSliderIcon()" (click)="nextSliderAddress()">
      <mat-icon class="icon-refer">arrow_forward_ios</mat-icon>
    </a>
  </div>
</div>
