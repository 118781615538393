import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import Cropper from "cropperjs";
import throttle from "lodash/throttle";

export interface ICropperResult {
  image: string;
  url: string;
}

@Component({
  selector: "app-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrl: "./image-cropper.component.scss",
  standalone: true,
})
export class ImageCropperComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) public imageSource!: SafeResourceUrl;
  @Input({ required: true }) public backgroundColor!: string;
  @Input({ required: true }) public aspectRatio!: number;

  @Output() public imageDestination = new EventEmitter<ICropperResult>();

  @ViewChild("image", { static: true }) public imageElement!: ElementRef;

  private cropper?: Cropper;
  private dimensions!: Cropper.GetCroppedCanvasOptions;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.aspectRatio) {
      this.dimensions = { width: 512, height: 512 / this.aspectRatio };
      this.cropper?.setAspectRatio(this.aspectRatio);
    }

    if (changes.backgroundColor) {
      if (this.cropper) {
        this.cropImage(this.cropper);
      }
    }
  }

  public ngAfterViewInit(): void {
    this.imageElement.nativeElement.style.backgroundColor =
      this.backgroundColor;

    this.cropper = new Cropper(this.imageElement.nativeElement, {
      scalable: false,
      dragMode: "move",
      aspectRatio: this.aspectRatio,
      crop: throttle(() => this.cropImage(this.cropper!), 150),
    });
  }

  private cropImage(cropper: Cropper): void {
    const canvas = cropper.getCroppedCanvas({
      ...this.dimensions,
      fillColor: this.backgroundColor,
    });

    canvas.toBlob((blob) => {
      if (blob) {
        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          this.imageDestination.emit({
            image: String(fileReader.result),
            url: canvas.toDataURL("image/jpg"),
          });
        };

        fileReader.readAsDataURL(blob);
      } else {
        // TODO: Add error reporting
      }
    });
  }
}
