<form #f="ngForm" *ngIf="partner">
  <section class="section">
    <h2 class="section-title no-padding-left">
      {{ "partner.edit.socialLinks" | translate }}
    </h2>
    <div class="section-subtitle">
      {{ "partner.edit.socialLinksInfo" | translate }}
    </div>
    <div class="row no-wrap">
      <div class="col width-100">
        <mat-form-field floatLabel="always">
          <mat-label>{{
            "partner.edit.websitePlaceholder" | translate
          }}</mat-label>
          <span matTextPrefix>https://</span>
          <input
            matInput
            #webUrl="ngModel"
            (change)="formatWebUrl()"
            [(ngModel)]="partner.webUrl"
            name="webUrl"
            pattern="{{ URL_VALIDATION_PATTERN }}"
          />
          <mat-error
            *ngIf="partner.webUrl && webUrl.errors && webUrl.errors.pattern"
          >
            {{ "partner.edit.enterValidUrl" | translate }}
          </mat-error>
          <app-tooltip
            text="{{ 'partner.edit.websiteTooltip' | translate }}"
            matSuffix
          />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <mat-label>{{ "partner.edit.twitterPlaceholder" | translate }}</mat-label>
      <input
        matInput
        #twitter="ngModel"
        [(ngModel)]="partner.twitter"
        name="twitter"
      />
      <app-tooltip
        text="{{ 'partner.edit.twitterTooltip' | translate }}"
        matSuffix
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{
        "partner.edit.instagramPlaceholder" | translate
      }}</mat-label>
      <input
        matInput
        #addressNumber="ngModel"
        [(ngModel)]="partner.instagram"
        name="instagram"
      />
      <app-tooltip
        text="{{ 'partner.edit.instagramTooltip' | translate }}"
        matSuffix
      />
    </mat-form-field>
    <div class="row no-wrap">
      <div class="col width-100">
        <mat-form-field floatLabel="always">
          <mat-label>{{
            "partner.edit.blogPlaceholder" | translate
          }}</mat-label>
          <span matTextPrefix>https://</span>
          <input
            (change)="formatBlogUrl()"
            matInput
            #instagram="ngModel"
            [(ngModel)]="partner.blogUrl"
            name="blog"
            type="url"
            pattern="{{ URL_VALIDATION_PATTERN }}"
          />
          <mat-error>
            {{ "partner.edit.enterValidUrl" | translate }}
          </mat-error>
          <app-tooltip
            text="{{ 'partner.edit.blogTooltip' | translate }}"
            matSuffix
          />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <mat-label>{{ "partner.edit.otherPlaceholder" | translate }}</mat-label>
      <input
        matInput
        #other="ngModel"
        [(ngModel)]="partner.other"
        name="other"
      />
      <app-tooltip
        text="{{ 'partner.edit.otherTooltip' | translate }}"
        matSuffix
      />
    </mat-form-field>

    <h2 id="mailing-metrics" class="section-title no-padding-left">
      {{ "partner.edit.mailingTitle" | translate }}
    </h2>

    <div class="row no-wrap">
      <div class="col width-100">
        <mat-form-field floatLabel="always">
          <mat-label>{{
            "partner.edit.privacyPolicyLink" | translate
          }}</mat-label>
          <span matTextPrefix>https://</span>
          <input
            matInput
            #privacyLink="ngModel"
            [(ngModel)]="partner.privacyPolicyLink"
            name="privacyLink"
            (input)="handlePrivacyPolicyLinkChanged()"
            (focusout)="handlePrivacyPolicyLinkFocusOut()"
            (keyup)="handlePrivacyPolicyLinkKeyUp()"
            pattern="{{ URL_VALIDATION_PATTERN }}"
          />
          <mat-error>
            {{ "partner.edit.enterValidUrl" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-checkbox
      [disabled]="!partner.privacyPolicyLink"
      [(ngModel)]="partner.offerNewsletterSubscription"
      name="offerNewsletterSubscription"
      (input)="handleOfferNewsletterSubscriptionChanged()"
    >
      {{ "partner.offerNewsletterSubscription" | translate }}
    </mat-checkbox>
  </section>
</form>
