<span class="close-icon" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</span>
<!-- Edit post -->
<h2 mat-dialog-title>{{ message | translate }}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{
      "partner.declineCampaignDialog.reasonTitle" | translate
    }}</mat-label>
    <textarea
      #declineReason
      matInput
      rows="5"
      maxlength="255"
      name="decline-reason"
      type="text"
      required
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    [disabled]="!declineReason.value"
    class="button-orange button-left"
    [mat-dialog-close]="declineReason.value"
  >
    {{ "shared.reject" | translate }}
  </button>
  <button class="button-grey dismiss" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
</mat-dialog-actions>
