<h2 mat-dialog-title>{{ "partner.edit.logosSectionTitle" | translate }}</h2>
<mat-dialog-content>
  <div class="editor">
    <div class="cropper-hint">
      {{ "partner.edit.filesSection.scrollToZoom" | translate }}
    </div>
    <app-image-cropper
      [imageSource]="originalImage"
      [backgroundColor]="backgroundColor"
      [aspectRatio]="aspectRatio"
      (imageDestination)="onImageChanged($event)"
    />
  </div>
  <div class="results">
    <div class="result">
      <mat-label>Proportion</mat-label>
      <mat-radio-group class="availableRatios" [(ngModel)]="aspectRatio">
        <mat-radio-button class="radio-item" [value]="1">1:1</mat-radio-button>
        <mat-radio-button class="radio-item" [value]="4 / 1"
          >4:1</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="result">
      <mat-label>Background</mat-label>
      <mat-select [(ngModel)]="backgroundColor">
        <mat-select-trigger>
          <div
            class="color-box"
            [ngStyle]="{ 'background-color': backgroundColor }"
          ></div>
          <div>{{ backgroundColor }}</div>
        </mat-select-trigger>
        <mat-option value="transparent">Transparent</mat-option>
        <mat-option value="white">White</mat-option>
        <mat-option value="grey">Grey</mat-option>
        <mat-option value="black">Black</mat-option>
      </mat-select>
    </div>
    <img
      *ngIf="aspectRatio === 4 / 1"
      [src]="imageDestination.url"
      class="img-preview-landscape"
      [style.width.px]="256"
    />
    <img
      *ngIf="aspectRatio === 1"
      [src]="imageDestination.url"
      class="img-preview"
      [style.width.px]="180 * aspectRatio"
    />
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="button-grey" (click)="close()">
    {{ "shared.cancel" | translate }}
  </button>
  <button class="button-orange" (click)="uploadLogo()">
    {{ "shared.save" | translate }}
  </button>
</mat-dialog-actions>
