import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { lastValueFrom } from "rxjs";
import { PartnerService } from "../shared/services/api/partner.service";
import { AuthenticationService } from "../shared/services/api/authentication.service";
import { AdminUrl } from "../admin/admin.url";
import { getUrlWithId } from "../shared/utils/get-url-with-id";

export const RedirectToPartnerIdBasedRouteGuard =
  (): CanActivateFn =>
  async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> => {
    const NOT_FOUND = "/not-found";
    const authService = inject(AuthenticationService);
    const partnerService = inject(PartnerService);
    const router = inject(Router);
    const lastSelectedPartnerId = partnerService.lastSelectedPartnerId;
    const url = state.url;

    if (lastSelectedPartnerId) {
      router.navigate([
        getUrlWithId(url, "partner", lastSelectedPartnerId) ?? NOT_FOUND,
      ]);
    } else if (authService.isRolePartner()) {
      const partners = await lastValueFrom(partnerService.getPartners());

      if (partners.length) {
        router.navigate([
          getUrlWithId(url, "partner", partners[0].id) ?? NOT_FOUND,
        ]);
      } else {
        router.navigate([NOT_FOUND]);
      }
    } else if (authService.isRoleAdmin()) {
      router.navigate([AdminUrl.Home()]);
    } else {
      router.navigate([NOT_FOUND]);
    }

    return false;
  };
