<section class="container">
  <div *ngIf="!loading" class="content">
    <div class="title">{{ "partner.paymentList.title" | translate }}</div>
    <table mat-table [dataSource]="paymentsDataSource" class="">
      <ng-container matColumnDef="invoiceId">
        <td mat-cell *matCellDef="let element">
          <strong>#{{ element.invoiceId }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="campaignName">
        <td mat-cell *matCellDef="let element">
          <strong>{{ element.campaignName }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: getDateTimeFormat() }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <td mat-cell *matCellDef="let element">
          <strong>{{ element.currencySymbol }}{{ element.amount }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="completed">
        <td mat-cell *matCellDef="let element">
          <span class="success-label">{{
            "partner.paymentList.succeed" | translate
          }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <td mat-cell *matCellDef="let element">
          <a
            *ngIf="!element.downloading"
            title="{{ 'partner.paymentList.downloadInvoice' | translate }}"
            (click)="downloadInvoice(element)"
            class="row-action"
          >
            <mat-icon>vertical_align_bottom</mat-icon>
          </a>
          <div class="downloading">
            <mat-spinner
              *ngIf="element.downloading"
              [diameter]="20"
            ></mat-spinner>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="totalPayments === 0" class="no-payments">
      {{ "partner.paymentList.noPayments" | translate }}
    </div>
  </div>
</section>
