<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #emailsChips [formControl]="control">
    @for (email of control.value; track email) {
      <mat-chip-row
        [class]="{
          'invalid-email': !isValidEmail(email),
          'duplicate-email': isDuplicateEmail(email)
        }"
        (click)="copyToClipboard(email)"
        (removed)="remove(email)"
      >
        {{ email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    }
    <input
      type="email"
      [matChipInputFor]="emailsChips"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  @if (control.valid && hint) {
    <mat-hint>{{ hint }}</mat-hint>
  } @else if (control.hasError("required")) {
    <mat-error>
      {{ "shared.emailAddressIsRequired" | translate }}
    </mat-error>
  } @else if (control.hasError("emailList")) {
    <mat-error>
      {{ "shared.enterAValidEmailAddress" | translate }}
    </mat-error>
  } @else if (control.hasError("noDuplicates")) {
    <mat-error>
      {{ "partner.edit.removeDuplicatedEmail" | translate }}
    </mat-error>
  }
</mat-form-field>
